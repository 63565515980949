import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setTab } from "../store/slices/navigation";
import Modal from "react-overlays/Modal";
import "../App.scss";
import PurchasedSupplier from "./inputTable/1_1";
import PurchasedHybrid from "./inputTable/1_2";
import PurchasedHybrid1 from "./inputTable/1_2_1";
import PurchasedAverage from "./inputTable/1_3";
import PurchasedSpend from "./inputTable/1_4";
import UpstreamFuel from "./inputTable/3_1";
import UpstreamDistance from "./inputTable/3_2";
import UpstreamSpend from "./inputTable/3_3";
import SiteSpecificMethod from "./inputTable/3_4";
import AverageDistribution from "./inputTable/3_5";
import WasteWaste from "./inputTable/4_1";
import WasteAverage from "./inputTable/4_2";
import BusinessFuel from "./inputTable/5_1";
import EmployeeDistance from "./inputTable/6_1";
import EmployeeAverage from "./inputTable/6_2";
import UpstreamAsset from "./inputTable/7_1";
import ProcessingSite from "./inputTable/9_1";
import ProcessingAverage from "./inputTable/9_2";
import UseDirect from "./inputTable/10_1";
import UseIndirect from "./inputTable/10_2";
import SoldImmidiate from "./inputTable/10_3";
import EndWaste from "./inputTable/11_1";
import Downstream from "./inputTable/12_1";
import FranchisesSpecific from "./inputTable/13_1";
import FranchisesAverage from "./inputTable/13_2";
import InvestmentSpecific from "./inputTable/14_1";
import InvestmentAverage from "./inputTable/14_2";
import FuelTransmission from "./inputTable/1_15";
import CapitalGoodSupplier from "./inputTable/2_1";
import CapitalGoodHybrid from "./inputTable/2_2";
import CapitalGoodHybrid1 from "./inputTable/2_2_1";
import CapitalGoodProduct from "./inputTable/2_3";
import CapitalGoodSpend from "./inputTable/2_4";
import DownstreamTransportation from "./inputTable/8_1";
import FranchisesSpecificTest from "./inputTable/13_1test";
import {
  getAllJobsData,
  getCompaniesInvestmentData,
  getScopes,
  readFile,
} from "../api/calculation.api";
import {
  reset,
  resetCalculation,
  resetCategoryMethodNumber,
  setCalculation,
  setCalculationResult,
  setCategoryMethodNumber,
} from "../store/slices/calculation";

const predefinedCategoryOrder = [
  {
    title: "Purchased Goods and Services",
    details: [
      "Supplier-specific method",
      "Hybrid method",
      "Hybrid method (allocated scope 1, 2 emissions & waste data)",
      "Average-data method",
      "Spend-based method",
    ],
  }, // Example details, adjust as necessary
  {
    title: "Capital Goods",
    details: [
      "Supplier-specific method",
      "Hybrid method",
      "Hybrid method (allocated scope 1, 2 emissions & waste data)",
      "Average-product method",
      "Average spend-based method",
    ],
  },
  {
    title:
      "Fuel- and Energy-Related Activities Not Included in Scope 1 or Scope 2",
    details: ["Supplier-specific method", "Average-data method"],
  },
  {
    title: "Upstream Transportation and Distribution",
    details: [
      "Fuel-based method",
      "Distance-based method",
      "Spend-based method",
      "Site-specific method",
      "Average Method",
    ],
  },
  {
    title: "Waste Generated in Operations",
    details: [
      "Supplier-specific method",
      "Waste-type-specific method",
      "Average-data method",
    ],
  },
  { title: "Business Travel", details: ["Fuel-based method"] },
  {
    title: "Employee Commuting",
    details: ["Distance-based method", "Average-data method"],
  },
  { title: "Upstream Leased Assets", details: ["Asset-specific method"] },
  {
    title: "Downstream Transportation and Distribution",
    details: ["Downstream transportation method"],
  },
  {
    title: "Processing of Sold Products",
    details: ["Site-specific method", "Average-data method"],
  },
  {
    title: "Use of Sold Products",
    details: [
      "Supplier-specific method",
      "Average-data method",
      "Sold intermediate products",
    ],
  },
  {
    title: "End-of-Life Treatment of Sold Products",
    details: ["Waste-type-specific method"],
  },
  { title: "Downstream Leased Assets", details: ["Asset-specific method"] },
  {
    title: "Franchises",
    details: ["Franchise-specific method", "Average-data method"],
  },
  {
    title: "Investments",
    details: ["Project/Investment-specific method", "Average-data method"],
  },
];

function CalculationPage({ sideBarFlag, setSideBarFlag }) {
  const fileRef = useRef();
  const [dataset, setDataset] = useState([]);
  const [dataset1, setDataset1] = useState({});
  const [dataset2, setDataset2] = useState({});
  const [investmentData, setInvestmentData] = useState({});
  const [companyNames, setCompanyNames] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const renderBackdrop = (props) => <div className="backdrop" {...props} />;
  const handleClose = () => setShowModal(false);

  const [showModal1, setShowModal1] = useState(false);
  const renderBackdrop1 = (props) => <div className="backdrop" {...props} />;
  const handleClose1 = () => setShowModal1(false);

  const [showModal2, setShowModal2] = useState(false);
  const renderBackdrop2 = (props) => <div className="backdrop" {...props} />;
  const handleClose2 = () => setShowModal2(false);

  const [showModal3, setShowModal3] = useState(false);
  const renderBackdrop3 = (props) => <div className="backdrop" {...props} />;
  const handleClose3 = () => setShowModal3(false);

  const onClick = () => {
    fileRef.current.click();
    setShowModal1(false);
  };

  const handleReset = () => {
    dispatch(reset());
    window.location.reload();
    setShowModal3(false);
  };

  const showSnakBar = () => {
    const x = document.getElementById("snackbar");
    x.className = "show";
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 3000);
  };

  const handleFileParse = (e) => {
    const files = e.target.files;
    if (files) {
      const formData = new FormData();
      formData.append("csv", files[0]);
      readFile(formData)
        .then((res) => setDataset(res.data.data))
        .catch(() => {
          showSnakBar();
        });
    }
  };
  const handleFileParseCSV = () => {
    const campCampanyNames = companyNames.map((c) => c.toLowerCase());
    if (category === 13 || category === 14) {
      getCompaniesInvestmentData(campCampanyNames).then((res) =>
        setInvestmentData(res.data),
      );
      setShowModal(false);
      return;
    }

    getAllJobsData()
      .then((res) => setDataset1(res.data.data))
      .catch(() => {
        showSnakBar();
      });

    setShowModal(false);
  };

  useEffect(() => {
    dispatch(setTab({ tabId: 2 }));
    dispatch(resetCalculation());
    dispatch(resetCategoryMethodNumber());

    getScopes()
      .then((res) => setDataset2(res.data))
      .catch(() => {
        showSnakBar();
      });
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [listData, setListData] = useState(predefinedCategoryOrder);
  const [category, setCategory] = useState(0);
  const [method, setMethod] = useState(0);
  const [startCalculation, setStartCalculation] = useState(false);
  const [result, setResult] = useState();

  const setResultData = (key, data) => {
    setResult(data);
    dispatch(
      setCalculationResult({
        key: key,
        value: data,
      }),
    );
  };

  const setCategoryMethod = (data) => {
    if (
      category !== 0 &&
      category !== 1 &&
      category !== 3 &&
      category !== 4 &&
      category !== 6 &&
      category !== 9 &&
      category !== 10 &&
      category !== 13 &&
      category !== 14
    )
      return;

    dispatch(
      setCategoryMethodNumber({
        key: `category${category + 1}`,
        value: data.toString(),
      }),
    );
  };

  useEffect(() => {
    setCategoryMethod(method);
  }, [category, method]);

  const displaycase = () => {
    if ((category === 0) & (method === 0))
      return (
        <PurchasedSupplier
          dataset={dataset}
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 0) & (method === 1))
      return (
        <PurchasedHybrid
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 0) & (method === 2))
      return (
        <PurchasedHybrid1
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 0) & (method === 3))
      return (
        <PurchasedAverage
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 0) & (method === 4))
      return (
        <PurchasedSpend
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 1) & (method === 0))
      return (
        <CapitalGoodSupplier
          dataset={dataset}
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 1) & (method === 1))
      return (
        <CapitalGoodHybrid
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 1) & (method === 2))
      return (
        <CapitalGoodHybrid1
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 1) & (method === 3))
      return (
        <CapitalGoodProduct
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 1) & (method === 4))
      return (
        <CapitalGoodSpend
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 3) & (method === 0))
      return (
        <UpstreamFuel
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 3) & (method === 1))
      return (
        <UpstreamDistance
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 3) & (method === 2))
      return (
        <UpstreamSpend
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 3) & (method === 3))
      return (
        <SiteSpecificMethod
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 3) & (method === 4))
      return (
        <AverageDistribution
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 4) & (method === 0))
      return (
        <WasteWaste
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 4) & (method === 1))
      return (
        <WasteAverage
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 5) & (method === 0))
      return (
        <BusinessFuel
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 6) & (method === 0))
      return (
        <EmployeeDistance
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 6) & (method === 1))
      return (
        <EmployeeAverage
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 7) & (method === 0))
      return (
        <UpstreamAsset
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 8) & (method === 0))
      return (
        <DownstreamTransportation
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 9) & (method === 0))
      return (
        <ProcessingSite
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 9) & (method === 1))
      return (
        <ProcessingAverage
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 10) & (method === 0))
      return (
        <UseDirect
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 10) & (method === 1))
      return (
        <UseIndirect
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 10) & (method === 2))
      return (
        <SoldImmidiate
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 11) & (method === 0))
      return (
        <EndWaste
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 12) & (method === 0))
      return (
        <Downstream
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    if ((category === 13) & (method === 0))
      return (
        <FranchisesSpecificTest
          dataset2={dataset2}
          dataset={dataset}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
          investmentData={investmentData}
          setCompaniesNames={setCompanyNames}
        />
      );
    if ((category === 13) & (method === 1))
      return (
        <FranchisesAverage
          onChange={(key, data) => {
            setResultData(key, data);
          }}
          investmentData={investmentData}
          setCompaniesNames={setCompanyNames}
        />
      );
    if ((category === 14) & (method === 0))
      return (
        <InvestmentSpecific
          dataset2={dataset2}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
          investmentData={investmentData}
          setCompaniesNames={setCompanyNames}
        />
      );
    if ((category === 14) & (method === 1))
      return (
        <InvestmentAverage
          onChange={(key, data) => {
            setResultData(key, data);
          }}
          investmentData={investmentData}
          setCompaniesNames={setCompanyNames}
        />
      );
    if ((category === 2) & (method === 0))
      return (
        <FuelTransmission
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
    else
      return (
        <PurchasedSupplier
          dataset={dataset}
          dataset1={dataset1}
          onChange={(key, data) => {
            setResultData(key, data);
          }}
        />
      );
  };

  const displayresult = () => {
    if (startCalculation === true)
      return (
        <div>
          <div className="scopes-container">
            <div className="scopes">
              <div className="top">
                <span>Scope 3 Emission:</span>
                <div>
                  <span>{(result / 1000000).toFixed(3)}K</span>
                  <span>tCO2e</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    else
      return (
        <div>
          <div className="scopes-container">
            <div className="scopes">
              <div className="top">
                <span>Scope 3 Emission:</span>
                <div>
                  <span></span>
                  <span>tCO2e</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  };

  return (
    <div className="CalculationPage" onClick={() => setSideBarFlag(false)}>
      <Header sideBarFlag={sideBarFlag} setSideBarFlag={setSideBarFlag} />
      <div className="cluster">
        <span className="title">
          Scope 3 Emission Performance (tonnes of CO2)
        </span>
        <div className="main">
          <div className="box">
            <span className="title">15 Categories</span>
            {listData?.map((item, index) => {
              return (
                <span
                  className={index === category ? "selected" : ""}
                  key={"category" + index}
                  onClick={() => {
                    setCategory(index);
                    setMethod(0);
                  }}
                >
                  • {item?.title}
                </span>
              );
            })}
          </div>
          <div className="center">
            <div className="box">
              <span className="title">Methods for category {category + 1}</span>
              {listData[category]?.details?.map((item, index) => {
                return (
                  <span
                    className={method === index ? "selected" : ""}
                    key={"method" + index}
                    onClick={() => {
                      setMethod(index);
                    }}
                  >
                    • {item}
                  </span>
                );
              })}
            </div>
            <div className="box">
              {displaycase()}
              <div className="button" onClick={() => setShowModal1(true)}>
                Upload Data
              </div>
              {showModal1 && (
                <Modal
                  className="modal"
                  show={showModal1}
                  onHide={handleClose1}
                  renderBackdrop={renderBackdrop1}
                >
                  <div>
                    <div className="modal-header">
                      <div className="modal-title">Confirmation</div>
                      <div>
                        <span className="close-button" onClick={handleClose1}>
                          x
                        </span>
                      </div>
                    </div>
                    <div className="modal-desc">
                      <p>Are you sure you want to upload data?</p>
                    </div>
                    <div className="modal-footer">
                      <button className="secondary-button" onClick={onClick}>
                        Yes
                      </button>
                      <button className="primary-button" onClick={handleClose1}>
                        No
                      </button>
                    </div>
                  </div>
                </Modal>
              )}
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileRef}
                onChange={handleFileParse}
              />
              <div className="button" onClick={() => setShowModal(true)}>
                Import Emission Factor
              </div>
              {showModal && (
                <Modal
                  className="modal"
                  show={showModal}
                  onHide={handleClose}
                  renderBackdrop={renderBackdrop}
                >
                  <div>
                    <div className="modal-header">
                      <div className="modal-title">Confirmation</div>
                      <div>
                        <span className="close-button" onClick={handleClose}>
                          x
                        </span>
                      </div>
                    </div>
                    <div className="modal-desc">
                      <p>
                        Are you sure you want to import the emission factor?
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        className="secondary-button"
                        onClick={handleFileParseCSV}
                      >
                        Yes
                      </button>
                      <button className="primary-button" onClick={handleClose}>
                        No
                      </button>
                    </div>
                  </div>
                </Modal>
              )}
              <div className="button" onClick={() => setShowModal3(true)}>
                Reset
              </div>
              {showModal3 && (
                <Modal
                  className="modal"
                  show={showModal3}
                  onHide={handleClose3}
                  renderBackdrop={renderBackdrop3}
                >
                  <div>
                    <div className="modal-header">
                      <div className="modal-title">Confirmation</div>
                      <div>
                        <span className="close-button" onClick={handleClose3}>
                          x
                        </span>
                      </div>
                    </div>
                    <div className="modal-desc">
                      <p>Are you sure you want to reset data?</p>
                    </div>
                    <div className="modal-footer">
                      {/* <button className="secondary-button"> */}
                      <button
                        className="secondary-button"
                        onClick={handleReset}
                      >
                        Yes
                      </button>
                      <button className="primary-button" onClick={handleClose3}>
                        No
                      </button>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
          </div>
          <div className="box">
            <span className="title">Emission Overview</span>

            {displayresult()}

            {startCalculation ? (
              <div
                className="button red"
                onClick={() => {
                  setStartCalculation(!startCalculation);
                }}
              >
                Cancel Calculation
              </div>
            ) : (
              <div
                className="button"
                onClick={() => {
                  setStartCalculation(!startCalculation);
                }}
              >
                Start Calculation
              </div>
            )}
            <div className="button" onClick={() => setShowModal2(true)}>
              Emission display
            </div>
            {showModal2 && (
              <Modal
                className="modal"
                show={showModal2}
                onHide={handleClose2}
                renderBackdrop={renderBackdrop2}
              >
                <div>
                  <div className="modal-header">
                    <div className="modal-title">Confirmation</div>
                    <div>
                      <span className="close-button" onClick={handleClose2}>
                        x
                      </span>
                    </div>
                  </div>
                  <div className="modal-desc">
                    <p>Are you sure you want to go to Emission display page?</p>
                  </div>
                  <div className="modal-footer">
                    {/* <button className="secondary-button"> */}
                    <button
                      className="secondary-button"
                      onClick={() => navigate("/display")}
                    >
                      Yes
                    </button>
                    <button className="primary-button" onClick={handleClose2}>
                      No
                    </button>
                  </div>
                </div>
              </Modal>
            )}
          </div>
        </div>
      </div>
      <Footer />
      <div id="snackbar">Please Sign In to Calculate Emissions!</div>
    </div>
  );
}

export default CalculationPage;
