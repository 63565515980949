import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import { useDispatch } from "react-redux";
import useRTKStore from "../../store/useRTKStore";
import { setCalculation } from "../../store/slices/calculation";
function DownstreamTransportation({ onChange, dataset1 }) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_8_1 } = useRTKStore("calculation", ["temp_rows_8_1"]);
  const rows = JSON.parse(JSON.stringify(temp_rows_8_1));

  currentRef.current = false;

  const setCalculationData = (key, value) => {
    console.log(value);
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };
  useEffect(() => {
    if (!dataset1 || Object.keys(dataset1).length == 0) return;

    const k = [].concat([], rows);
    rows.forEach((d, idx) => {
      if (dataset1.hasOwnProperty(k[idx].Purchase)) {
        k[idx].factor = dataset1[k[idx].Purchase];
      } else {
        k[idx].factor = k[idx].factor;
      }
    });
    setCalculationData("temp_rows_8_1", k);
  }, [dataset1]);

  useEffect(() => {
    let sum = 0;
    rows.forEach((row) => {
      sum +=
        parseFloat(row.mgs) *
        parseFloat(row.tdd) *
        parseFloat(row.factor) *
        1000;
    });
    onChange("result8_1", sum);
  }, [rows]);

  console.log(rows);

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div className="container">
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Purchaser</th>
              <th>Mass of goods sold (tonnes)</th>
              <th>Total downstream distance transported (km)</th>
              <th>Transport mode or vehicle type</th>
              <th>Emission factor (kg CO2e/tonne-km)</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.Purchase}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.Purchase = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_8_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.mgs}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.mgs = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_8_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.tdd}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.tdd = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_8_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.tmv}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.tmv = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_8_1", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.factor === 0 ? "NOT FOUND" : row.factor}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.factor = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_8_1", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        Purchase: "",
                        mgs: "",
                        tdd: "",
                        tmv: "",
                        factor: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_8_1", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_8_1", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DownstreamTransportation;
