import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import { useDispatch } from "react-redux";
import useRTKStore from "../../store/useRTKStore";
import { setCalculation } from "../../store/slices/calculation";
function FranchisesAverage({ onChange, setCompaniesNames, investmentData }) {
  const currentRef = useRef();

  currentRef.current = false;
  const dispatch = useDispatch();
  const { temp_rows_13_2 } = useRTKStore("calculation", ["temp_rows_13_2"]);
  const rows = JSON.parse(JSON.stringify(temp_rows_13_2));

  useEffect(() => {
    let sum = 0;
    rows.forEach((row) => {
      sum += parseFloat(row.sa) * parseFloat(row.factor);
    });
    onChange("result13_2", sum);
  }, [rows]);

  useEffect(() => {
    const k = [].concat([], rows);
    k.forEach((dat, idx) => {
      dat.factor =
        investmentData[dat.Franchisee.toLowerCase()]?.scope12 || dat.factor;
    });
    setCalculationData("temp_rows_13_2", k);
  }, [investmentData]);

  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div className="container">
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Franchisee</th>
              <th>Type</th>
              <th>Shop area (m2) </th>
              <th>Emission factor (kg CO2e/m2/year)</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.Franchisee}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.Franchisee = e.target.value;
                        }
                        return i;
                      });
                      const newNames = newData.map((row) => row.Franchisee);
                      setCompaniesNames(newNames);
                      setCalculationData("temp_rows_13_2", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.Type}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.Type = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_13_2", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.sa}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.sa = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_13_2", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.factor === 0 ? "NOT FOUND" : row.factor}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.factor = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_13_2", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        Franchisee: "",
                        Type: "",
                        sa: "",
                        factor: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_13_2", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_13_2", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FranchisesAverage;
