import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import { useDispatch } from "react-redux";
import useRTKStore from "../../store/useRTKStore";
import { setCalculation } from "../../store/slices/calculation";
function FuelTransmission({ onChange, dataset1 }) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_1_15 } = useRTKStore("calculation", ["temp_rows_1_15"]);
  const rows = JSON.parse(JSON.stringify(temp_rows_1_15));

  currentRef.current = false;
  useEffect(() => {
    let sum = 0;
    rows.forEach((row) => {
      sum +=
        parseFloat(row.EP) * parseFloat(row.UEF) +
        (parseFloat(row.EP) * parseFloat(row.CEF) * parseFloat(row.TDR)) / 100 +
        parseFloat(row.DHP) * parseFloat(row.UEFH);
    });
    onChange("result15_1", sum);
  }, [rows]);

  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };

  useEffect(() => {
    if (!dataset1 || Object.keys(dataset1).length == 0) return;
    const k = [].concat([], rows);
    rows.forEach((d, idx) => {
      if (dataset1.hasOwnProperty(k[idx].Country1)) {
        k[idx].UEF = dataset1[k[idx].Country1];
      } else {
        k[idx].UEF = k[idx].UEF;
      }
    });
    setCalculationData("temp_rows_1_15", k);
  }, [dataset1]);

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div>
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Country name</th>
              <th colSpan={2}>Electricity purchased (kWh) </th>
              <th colSpan={2}>District heating purchased (kWh)</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.Country}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.Country = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_1_15", newData);
                    }}
                  />
                </td>
                <td colSpan={2}>
                  <input
                    type="text"
                    value={row.EP}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.EP = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_1_15", newData);
                    }}
                  />
                </td>
                <td colSpan={2}>
                  <input
                    type="text"
                    value={row.DHP === 0 ? "NOT FOUND" : row.DHP}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.DHP = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_1_15", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <th>Country name</th>
              <th>
                Upstream emission factor of purchased electricity (kg CO2e/kWh)
              </th>
              <th>Electricity/heat combustion emission factor (kg CO2e/kWh)</th>
              <th>T&D loss rate (percent)</th>
              <th>
                Upstream emission factor of purchased heating (kg CO2e/ kWh)
              </th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.Country1}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.Country1 = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_1_15", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.UEF}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.UEF = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_1_15", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.CEF}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.CEF = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_1_15", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.TDR}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.TDR = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_1_15", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.UEFH === 0 ? "NOT FOUND" : row.UEFH}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.UEFH = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_1_15", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        Country: "",
                        EP: "",
                        DHP: "",
                        Country1: "",
                        UEF: "",
                        CEF: "",
                        TDR: "",
                        UEFH: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_1_15", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_1_15", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FuelTransmission;
