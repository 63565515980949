import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import useRTKStore from "../../store/useRTKStore";
import { useDispatch } from "react-redux";
import { setCalculation } from "../../store/slices/calculation";
function BusinessFuel({ onChange, dataset1 }) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_5_1_1, temp_rows_5_1_2 } = useRTKStore("calculation", [
    "temp_rows_5_1_1",
    "temp_rows_5_1_2",
  ]);
  const rows = JSON.parse(JSON.stringify(temp_rows_5_1_1));
  const rows1 = JSON.parse(JSON.stringify(temp_rows_5_1_2));

  currentRef.current = false;

  useEffect(() => {
    let sum = 0;
    let sum1 = 0;
    rows.forEach((row) => {
      sum +=
        (parseFloat(row.neg) / parseFloat(row.aev)) *
        parseFloat(row.distance) *
        parseFloat(row.factor);
    });
    rows1.forEach((row1) => {
      sum1 +=
        parseFloat(row1.neg) *
        parseFloat(row1.distance) *
        parseFloat(row1.factor);
    });
    onChange("result5_1", sum + sum1);
  }, [rows, rows1]);

  useEffect(() => {
    if (!dataset1 || Object.keys(dataset1).length == 0) return;
    const k = [].concat([], rows);
    rows.forEach((d, idx) => {
      if (dataset1.hasOwnProperty(k[idx].Group)) {
        k[idx].factor = dataset1[k[idx].Group];
      } else {
        k[idx].factor = k[idx].factor;
      }
    });
    setCalculationData("temp_rows_5_1_1", k);

    const k1 = [].concat([], rows1);
    rows1.forEach((d, idx) => {
      if (dataset1.hasOwnProperty(k1[idx].Group)) {
        k1[idx].factor = dataset1[k1[idx].Group];
      } else {
        k1[idx].factor = k1[idx].factor;
      }
    });
    setCalculationData("temp_rows_5_1_2", k1);
  }, [dataset1]);

  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div className="container">
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Employee Group</th>
              <th>Number of employees in group</th>
              <th>Car type</th>
              <th>Average employees per vehicle</th>
              <th>Location</th>
              <th>Distance(km)</th>
              <th>Emission factor(kg CO2e/ vehicle-km)</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.Group}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.Group = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.neg}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.neg = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.ct}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.ct = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.aev}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.aev = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.location}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.location = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.distance}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.distance = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.factor === 0 ? "NOT FOUND" : row.factor}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.factor = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        Group: "",
                        neg: "",
                        ct: "",
                        aev: "",
                        location: "",
                        distance: "",
                        factor: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <th>Employee Group</th>
              <th>Number of employees in group</th>
              <th colSpan={2}>Flight type</th>
              <th colSpan={2}>Distance(km)</th>
              <th>Emission factor(kg CO2e/ vehicle-km)</th>
            </tr>
            {rows1.map((row1) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row1.Group}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row1.id) {
                          i.Group = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row1.neg}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row1.id) {
                          i.neg = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                </td>
                <td colSpan={2}>
                  <input
                    type="text"
                    value={row1.ft}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row1.id) {
                          i.ft = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                </td>
                <td colSpan={2}>
                  <input
                    type="text"
                    value={row1.distance}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row1.id) {
                          i.distance = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row1.factor === 0 ? "NOT FOUND" : row1.factor}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows1.map((i) => {
                        if (i.id === row1.id) {
                          i.factor = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        Group: "",
                        neg: "",
                        ft: "",
                        distance: "",
                        factor: "",
                      };
                      let pos =
                        rows1.indexOf(
                          rows1.find((item) => item.id === row1.id),
                        ) + 1;

                      const newData = [].concat(
                        rows1.slice(0, pos),
                        newRow,
                        rows1.slice(pos),
                      );
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows1.indexOf(
                        rows1.find((item) => item.id == row1.id),
                      );
                      const newData = [].concat(
                        rows1.slice(0, pos),
                        rows1.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_5_1_1", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default BusinessFuel;
