import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import { useDispatch } from "react-redux";
import useRTKStore from "../../store/useRTKStore";
import { setCalculation } from "../../store/slices/calculation";
function Downstream({ onChange, dataset1 }) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_12_1 } = useRTKStore("calculation", ["temp_rows_12_1"]);
  const rows = JSON.parse(JSON.stringify(temp_rows_12_1));
  currentRef.current = false;
  useEffect(() => {
    if (!dataset1 || Object.keys(dataset1).length == 0) return;

    const k = [].concat([], rows);
    rows.forEach((d, idx) => {
      if (dataset1.hasOwnProperty(k[idx].factory)) {
        k[idx].space = dataset1[k[idx].factory];
      } else {
        k[idx].space = k[idx].space;
      }
    });
    setCalculationData("temp_rows_12_1", k);
  }, [dataset1]);

  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };
  useEffect(() => {
    let sum = 0;
    let total = 0;
    let totlemission = 0;
    rows.forEach((row) => {
      total += parseFloat(row.space);
      totlemission += parseFloat(row.emission);
    });
    // return total
    sum += (totlemission * parseFloat(rows[0].space)) / parseFloat(total);
    console.log(sum);
    onChange("result12_1", sum);
  }, [rows]);

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div className="container">
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Factory</th>
              <th>Combined scope 1 and scope 2 emissions (kg CO2e)</th>
              <th>Floor space (m2)</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.factory}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.factory = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_12_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.emission}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.emission = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_12_1", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.space === 0 ? "NOT FOUND" : row.space}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.space = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_12_1", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        factory: "",
                        emission: "",
                        space: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_12_1", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_12_1", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Downstream;
