import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: "",
  companyName: "",
  email: "",
  phoneNumber: "",
  id: 0,
  token: "",
  isEmailVerified: false,
  isLinkedInUser: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.email = action.payload.email;
      state.user = action.payload.user;
      state.companyName = action.payload.companyName;
      state.phoneNumber = action.payload.phoneNumber;
      state.id = action.payload.id;
      state.token = action.payload.token;
      state.isEmailVerified = action.payload.isEmailVerified;
      state.isLinkedInUser = action.payload.isLinkedInUser;
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload.phoneNumber;
    },
    setEmail: (state, action) => {
      state.email = action.payload.email;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload.companyName;
    },
    setisEmailVerified: (state, action) => {
      state.isEmailVerified = action.payload.isEmailVerified;
    },
    setId: (state, action) => {
      state.id = action.payload.id;
    },
    setToken: (state, action) => {
      state.token = action.payload.token;
    },
    setIsLinkedInUser: (state, action) => {
      state.isLinkedInUser = action.payload.isLinkedInUser;
    },
    reset: () => initialState,
  },
});

export const {
  setUserData,
  setUser,
  setEmail,
  setPhoneNumber,
  setCompanyName,
  setIsLinkedInUser,
  setId,
  setToken,
  setisEmailVerified,
  reset,
} = userSlice.actions;

export default userSlice.reducer;
