import React, { useEffect, useState, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import { useDispatch } from "react-redux";
import { setCalculation } from "../../store/slices/calculation";
import useRTKStore from "../../store/useRTKStore";
function CapitalGoodSupplier({ onChange, dataset, dataset1 }) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_2_1 } = useRTKStore("calculation", ["temp_rows_2_1"]);
  const rows = JSON.parse(JSON.stringify(temp_rows_2_1));

  currentRef.current = false;

  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };

  useEffect(() => {
    if (!dataset1 || Object.keys(dataset1).length == 0) return;

    const k = [].concat([], rows);
    rows.forEach((d, idx) => {
      if (dataset1.hasOwnProperty(k[idx].gool)) {
        k[idx].ssef = dataset1[k[idx].gool];
      } else {
        k[idx].ssef = k[idx].ssef;
      }
    });
    setCalculationData("temp_rows_2_1", k);
  }, [dataset1]);

  useEffect(() => {
    if (!dataset || !dataset.length) return;
    const k = [].concat([], rows);
    dataset[0].forEach((d, idx) => {
      k[idx].qp = d[0];
      k[idx].ssef = d[1];
    });
    setCalculationData("temp_rows_2_1", k);
  }, [dataset]);

  useEffect(() => {
    let sum = 0;
    rows.forEach((row) => {
      sum += parseFloat(row.qp) * parseFloat(row.ssef) || 0;
    });
    onChange("result2_1", sum);
  }, [rows]);

  return (
    <div>
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Purchased good</th>
              <th>Supplier</th>
              <th>Quantities purchased(kg)</th>
              <th>Surpplier-specific emission factor(kgco2/kg)</th>
            </tr>

            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.gool}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.gool = e.target.value;
                          localStorage.setItem("1_1_gool", i.gool);
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.supplier}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.supplier = e.target.value;
                          localStorage.setItem("1_1_supplier", i.supplier);
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.qp}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.qp = e.target.value;
                          localStorage.setItem("1_1_qp", i.qp);
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_1", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.ssef === 0 ? "Not Found" : row.ssef}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.ssef = e.target.value;
                          localStorage.setItem("1_1_ssef", i.ssef);
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_1", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        gool: "",
                        supplier: "",
                        qp: "",
                        ssef: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_2_1", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_2_1", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CapitalGoodSupplier;
