export const seedData = {
  temp_rows: [
    {
      id: 0,
      gool: "Cement",
      supplier: "Supplier C",
      qp: "200000",
      ssef: "0.15",
    },
    {
      id: 1,
      gool: "Plaster",
      supplier: "Supplier D",
      qp: "600000",
      ssef: "0.1",
    },
    {
      id: 2,
      gool: "Paint",
      supplier: "Supplier E",
      qp: "200000",
      ssef: "0.1",
    },
    {
      id: 3,
      gool: "Timber",
      supplier: "Supplier F",
      qp: "100000",
      ssef: "0.25",
    },
    {
      id: 4,
      gool: "Concrete",
      supplier: "Supplier G",
      qp: "50000",
      ssef: "0.2",
    },
  ],
  temp_rows_1_2_1_1: [
    {
      id: 0,
      gool: "Electricity",
      qp: "5000",
      ssef: "0.5",
    },
    {
      id: 1,
      gool: "Natural gas",
      qp: "2500",
      ssef: "0.2",
    },
  ],
  temp_rows_1_2_1_2: [
    {
      id: 0,
      gool: "T-shirts",
      qp: "12000",
      ssef: "5.6",
    },
  ],
  temp_rows_1_2_1_3: [
    {
      id: 0,
      gool: "Waste sent to landfill",
      qp: "100",
      ssef: "0.5",
    },
  ],
  temp_rows_1_2_1: [
    {
      id: 0,
      gool: "Electricity",
      qp: "5000",
      ssef: "0.5",
    },
    {
      id: 1,
      gool: "Natural gas",
      qp: "2500",
      ssef: "0.2",
    },
  ],
  temp_rows_1_2_2: [
    {
      id: 0,
      gool: "Cotton",
      qp: "5000",
      ssef: "7",
    },
    {
      id: 1,
      gool: "Polymer",
      qp: "2500",
      ssef: "5",
    },
    {
      id: 2,
      gool: "Chemical A",
      qp: "500",
      ssef: "2",
    },
    {
      id: 3,
      gool: "Chemical B",
      qp: "500",
      ssef: "1.5",
    },
  ],
  temp_rows_1_2_3: [
    {
      id: 0,
      gool: "Cotton1",
      qp: "1000",
      ssef: "0.01",
    },
    {
      id: 1,
      gool: "Polymer1",
      qp: "2500",
      ssef: "0.02",
    },
    {
      id: 2,
      gool: "Chemical A1",
      qp: "800",
      ssef: "0.05",
    },
    {
      id: 3,
      gool: "Chemical B1",
      qp: "200",
      ssef: "0.1",
    },
  ],
  temp_rows_1_2_4: [
    {
      id: 0,
      gool: "Waste sent to landfill",
      qp: "100",
      ssef: 0,
    },
  ],
  temp_rows_1_3: [
    {
      id: 0,
      gool: "Hard drive",
      qp: "400",
      ssef: "20",
    },
    {
      id: 1,
      gool: "Integrated circuits",
      qp: "200",
      ssef: "10",
    },
    {
      id: 2,
      gool: "Liquid Crystal Display (LCD)",
      qp: "500",
      ssef: "40",
    },
    {
      id: 3,
      gool: "Semiconductors",
      qp: "100",
      ssef: "70",
    },
    {
      id: 4,
      gool: "Battery",
      qp: "1500",
      ssef: "3",
    },
    {
      id: 5,
      gool: "Keyboard",
      qp: "300",
      ssef: 0,
    },
  ],
  temp_rows_1_4_1: [
    {
      id: 0,
      gool: "Hard drive",
      qp: "400",
      ssef: "20",
    },
    {
      id: 1,
      gool: "Integrated circuits",
      qp: "200",
      ssef: "10",
    },
    {
      id: 2,
      gool: "Liquid Crystal Display (LCD)",
      qp: "500",
      ssef: "40",
    },
    {
      id: 3,
      gool: "Semiconductors",
      qp: "100",
      ssef: "70",
    },
    {
      id: 4,
      gool: "Battery",
      qp: "1500",
      ssef: "3",
    },
    {
      id: 5,
      gool: "Keyboard",
      qp: "300",
      ssef: "3",
    },
  ],
  temp_rows_1_4_2: [
    {
      id: 0,
      gool: "Plastic (PS)",
      qp: "5000",
      ssef: "0.3",
    },
    {
      id: 1,
      gool: "Plastic (ABS)",
      qp: "3000",
      ssef: "0.3",
    },
    {
      id: 2,
      gool: "PET (film)",
      qp: "4000",
      ssef: "0.3",
    },
    {
      id: 3,
      gool: "Aluminum",
      qp: "6000",
      ssef: "0.5",
    },
    {
      id: 4,
      gool: "Steel",
      qp: "1500",
      ssef: "0.2",
    },
    {
      id: 5,
      gool: "Cyclohexane",
      qp: "5000",
      ssef: "0.2",
    },
    {
      id: 6,
      gool: "Epoxy resin",
      qp: "5000",
      ssef: "0.3",
    },
    {
      id: 7,
      gool: "Copper",
      qp: "1000",
      ssef: "0.3",
    },
    {
      id: 8,
      gool: "Glass",
      qp: "5000",
      ssef: "0.4",
    },
  ],
  temp_rows_2_1: [
    {
      id: 0,
      gool: "Cement",
      supplier: "Supplier C",
      qp: "200000",
      ssef: "0.15",
    },
    {
      id: 1,
      gool: "Plaster",
      supplier: "Supplier D",
      qp: "600000",
      ssef: "0.1",
    },
    {
      id: 2,
      gool: "Paint",
      supplier: "Supplier E",
      qp: "200000",
      ssef: "0.1",
    },
    {
      id: 3,
      gool: "Timber",
      supplier: "Supplier F",
      qp: "100000",
      ssef: "0.25",
    },
    {
      id: 4,
      gool: "Concrete",
      supplier: "Supplier G",
      qp: "50000",
      ssef: "0.2",
    },
  ],
  temp_rows_2_2_1_1: [
    {
      id: 0,
      gool: "Electricity",
      qp: "5000",
      ssef: "0.5",
    },
    {
      id: 1,
      gool: "Natural gas",
      qp: "2500",
      ssef: "0.2",
    },
  ],
  temp_rows_2_2_1_2: [
    {
      id: 0,
      gool: "T-shirts",
      qp: "12000",
      ssef: "5.6",
    },
  ],
  temp_rows_2_2_1_3: [
    {
      id: 0,
      gool: "Waste sent to landfill",
      qp: "100",
      ssef: "0.5",
    },
  ],
  temp_rows_2_2_1: [
    {
      id: 0,
      gool: "Electricity",
      qp: "5000",
      ssef: "0.5",
    },
    {
      id: 1,
      gool: "Natural gas",
      qp: "2500",
      ssef: "0.2",
    },
  ],
  temp_rows_2_2_2: [
    {
      id: 0,
      gool: "Cotton",
      qp: "5000",
      ssef: "7",
    },
    {
      id: 1,
      gool: "Polymer",
      qp: "2500",
      ssef: "5",
    },
    {
      id: 2,
      gool: "Chemical A",
      qp: "500",
      ssef: "2",
    },
    {
      id: 3,
      gool: "Chemical B",
      qp: "500",
      ssef: "1.5",
    },
  ],
  temp_rows_2_2_3: [
    {
      id: 0,
      gool: "Cotton1",
      qp: "1000",
      ssef: "0.01",
    },
    {
      id: 1,
      gool: "Polymer1",
      qp: "2500",
      ssef: "0.02",
    },
    {
      id: 2,
      gool: "Chemical A1",
      qp: "800",
      ssef: "0.05",
    },
    {
      id: 3,
      gool: "Chemical B1",
      qp: "200",
      ssef: "0.1",
    },
  ],
  temp_rows_2_2_4: [
    {
      id: 0,
      gool: "Waste sent to landfill",
      qp: "100",
      ssef: 0,
    },
  ],
  temp_rows_2_3: [
    {
      id: 0,
      gool: "Hard drive",
      qp: "400",
      ssef: "20",
    },
    {
      id: 1,
      gool: "Integrated circuits",
      qp: "200",
      ssef: "10",
    },
    {
      id: 2,
      gool: "Liquid Crystal Display (LCD)",
      qp: "500",
      ssef: "40",
    },
    {
      id: 3,
      gool: "Semiconductors",
      qp: "100",
      ssef: "70",
    },
    {
      id: 4,
      gool: "Battery",
      qp: "1500",
      ssef: "3",
    },
    {
      id: 5,
      gool: "Keyboard",
      qp: "300",
      ssef: 0,
    },
  ],
  temp_rows_2_4_1: [
    {
      id: 0,
      gool: "Hard drive",
      qp: "400",
      ssef: "20",
    },
    {
      id: 1,
      gool: "Integrated circuits",
      qp: "200",
      ssef: "10",
    },
    {
      id: 2,
      gool: "Liquid Crystal Display (LCD)",
      qp: "500",
      ssef: "40",
    },
    {
      id: 3,
      gool: "Semiconductors",
      qp: "100",
      ssef: "70",
    },
    {
      id: 4,
      gool: "Battery",
      qp: "1500",
      ssef: "3",
    },
    {
      id: 5,
      gool: "Keyboard",
      qp: "300",
      ssef: "3",
    },
  ],
  temp_rows_2_4_2: [
    {
      id: 0,
      gool: "Plastic (PS)",
      qp: "5000",
      ssef: "0.3",
    },
    {
      id: 1,
      gool: "Plastic (ABS)",
      qp: "3000",
      ssef: "0.3",
    },
    {
      id: 2,
      gool: "PET (film)",
      qp: "4000",
      ssef: "0.3",
    },
    {
      id: 3,
      gool: "Aluminum",
      qp: "6000",
      ssef: "0.5",
    },
    {
      id: 4,
      gool: "Steel",
      qp: "1500",
      ssef: "0.2",
    },
    {
      id: 5,
      gool: "Cyclohexane",
      qp: "5000",
      ssef: "0.2",
    },
    {
      id: 6,
      gool: "Epoxy resin",
      qp: "5000",
      ssef: "0.3",
    },
    {
      id: 7,
      gool: "Copper",
      qp: "1000",
      ssef: "0.3",
    },
    {
      id: 8,
      gool: "Glass",
      qp: "5000",
      ssef: "0.4",
    },
  ],
  temp_rows_3_1: [
    {
      id: 0,
      gool: "B",
      qp: "5000",
      supplier: "Diesel",
      ssef: "3",
    },
    {
      id: 1,
      gool: "C",
      qp: "8000",
      supplier: "Diesel",
      ssef: "3",
    },
    {
      id: 2,
      gool: "D",
      qp: "9000",
      supplier: "Diesel",
      ssef: "3",
    },
    {
      id: 3,
      gool: "D",
      qp: "50",
      supplier: "Refrigerant R410a",
      ssef: "2000",
    },
  ],
  temp_rows_3_2: [
    {
      id: 0,
      gool: "B",
      mt: "2",
      qp: "20000",
      supplier: "Truck (rigid, 3.5-7.5t)",
      ssef: "0.2",
    },
    {
      id: 1,
      gool: "C",
      mt: "1",
      qp: "30000",
      supplier: "Air (long haul)",
      ssef: "1",
    },
    {
      id: 2,
      gool: "D",
      mt: "6",
      qp: "40000",
      supplier: "Container 2,000–2,999 TEU",
      ssef: "0.05",
    },
  ],
  temp_rows_3_3: [
    {
      id: 0,
      gool: "B",
      qp: "200000",
      supplier: "Truck (rigid, 3.5-7.5t)",
      ssef: "0.04",
    },
    {
      id: 1,
      gool: "C",
      qp: "300000",
      supplier: "Air (long haul)",
      ssef: "0.15",
    },
    {
      id: 2,
      gool: "D",
      qp: "400000",
      supplier: "Container 2,000–2,999 TEU",
      ssef: "0.05",
    },
  ],
  temp_rows_3_4: [
    {
      id: 0,
      sf: 1,
      ec: 10000,
      eef: 0.8,
      ngu: 1000,
      nge: 0.25,
      wcg: 100,
      tvs: 400,
    },
    {
      id: 1,
      sf: 2,
      ec: 15000,
      eef: 0.8,
      ngu: 2000,
      nge: 0.25,
      wcg: 200,
      tvs: 800,
    },
  ],
  temp_rows_3_5: [
    {
      id: 0,
      sf: "Distribution center",
      tvs: 4000,
      ads: 2,
      efs: 0.01,
    },
    {
      id: 1,
      sf: "Distribution center",
      tvs: 4000,
      ads: 2,
      efs: 0.02,
    },
  ],
  temp_rows_4_1: [
    {
      id: 0,
      gool: "Plastic",
      qp: "2000",
      supplier: "Landfill",
      ssef: "40",
    },
    {
      id: 1,
      gool: "Plastic",
      qp: "5000",
      supplier: "Incinerated",
      ssef: "2",
    },
    {
      id: 2,
      gool: "Plastic",
      qp: "4000",
      supplier: "Recycled",
      ssef: "10",
    },
    {
      id: 3,
      gool: "Plastic",
      qp: "5000",
      supplier: "Wastewater",
      ssef: "0.5",
    },
  ],
  temp_rows_4_2: [
    {
      id: 0,
      total: "40",
      gool: "Landfill",
      qp: "25",
      ssef: "300",
    },
    {
      id: 1,
      total: "40",
      gool: "Incinerated",
      qp: "5",
      ssef: 0,
    },
    {
      id: 2,
      total: "40",
      gool: "Recycled",
      qp: "30",
      ssef: 0,
    },
    {
      id: 3,
      total: "40",
      gool: "Recycled",
      qp: "20",
      ssef: "10",
    },
    {
      id: 4,
      total: "40",
      gool: "Composted",
      qp: "20",
      ssef: "30",
    },
  ],
  temp_rows_5_1_1: [
    {
      id: 0,
      Group: "Group 1",
      neg: "10",
      ct: "Hybrid",
      aev: "2",
      location: "United States",
      distance: "50",
      factor: "1",
    },
    {
      id: 1,
      Group: "Group 2",
      neg: "20",
      ct: "Average gasoline car",
      aev: "2",
      location: "Australia",
      distance: "200",
      factor: "2",
    },
    {
      id: 2,
      Group: "Group 3",
      neg: "100",
      ct: "Hybrid",
      aev: "3",
      location: "United States",
      distance: "100",
      factor: "4",
    },
  ],
  temp_rows_5_1_2: [
    {
      id: 0,
      Group: "Group 1",
      neg: "10",
      ft: "Long haul",
      distance: "1000",
      factor: "5",
    },
    {
      id: 1,
      Group: "Group 2",
      neg: "20",
      ft: "Short haul",
      distance: "1500",
      factor: "6",
    },
    {
      id: 2,
      Group: "Group 3",
      neg: "100",
      ft: "Long haul",
      distance: "1200",
      factor: "5",
    },
  ],
  temp_rows_6_1: [
    {
      id: 0,
      Employee: "A",
      rc: "5",
      owd: "100",
      ref: "0.1",
      cc: 0,
      cef: "0.2",
      odc: 0,
    },
    {
      id: 1,
      Employee: "B",
      rc: "4",
      owd: "100",
      ref: "0.1",
      cc: "1",
      cef: "0.2",
      odc: "150",
    },
    {
      id: 2,
      Employee: "C",
      rc: 0,
      owd: 0,
      ref: "0.1",
      cc: "5",
      cef: "0.2",
      odc: "200",
    },
  ],
  temp_rows_6_2: [
    {
      id: 0,
      Group: "Rail",
      ptc: "5",
      aod: "10",
      factor: "0.1",
    },
    {
      id: 1,
      Group: "Car",
      ptc: "3",
      aod: "15",
      factor: "0.2",
    },
    {
      id: 2,
      Group: "Foot",
      ptc: "15",
      aod: "1",
      factor: 0,
    },
    {
      id: 3,
      Group: "Bus",
      ptc: "5",
      aod: "5",
      factor: "0.1",
    },
  ],
  temp_rows_7_1: [
    {
      id: 0,
      Group: "Natural gas",
      rca: "200",
      bta: "2000",
      bor: "0.75",
      use: "1500",
      factor: "0.2",
    },
    {
      id: 1,
      Group: "Electricity",
      rca: "200",
      bta: "2000",
      bor: "0.75",
      use: "3000",
      factor: "0.7",
    },
    {
      id: 2,
      Group: "Assets",
      rca: "200",
      bta: "2000",
      bor: "0.75",
      use: "500",
      factor: "1500",
    },
  ],
  temp_rows_8_1: [
    {
      id: 0,
      Purchase: "B",
      mgs: "4",
      tdd: "1000",
      tmv: "Truck (rigid, 3.5-7.5t)",
      factor: "0.2",
    },
  ],
  temp_rows_9_1_1: [
    {
      id: 0,
      consumed: "Natural gas",
      amount: "350000",
      factor: "0.2",
    },
    {
      id: 1,
      consumed: "Electricity",
      amount: "200000",
      factor: "0.5",
    },
  ],
  temp_rows_9_1_2: [
    {
      id: 0,
      waste: "Waste products",
      amount: "350000",
      factor: "0.2",
    },
  ],
  temp_rows_9_2: [
    {
      id: 0,
      Process:
        "Candy mixing, cooking, molding, cooling, wrapping, and packaging",
      mass: "100000",
      factor: "1.5",
    },
  ],
  temp_rows_10_1: [
    {
      id: 0,
      Product: "Washing machine X100",
      tul: "1000",
      ns: "115",
      ecu: "1.3",
      factor: "0.5",
    },
    {
      id: 1,
      Product: "Washing machine X200",
      tul: "1100",
      ns: "190",
      ecu: "1.5",
      factor: "0.5",
    },
    {
      id: 2,
      Product: "Iron Y123",
      tul: "2000",
      ns: "200",
      ecu: "0.2",
      factor: "0.5",
    },
  ],
  temp_rows_10_2: [
    {
      id: 0,
      Setting: "30°C cotton wash",
      lup: "1000",
      cts: "20",
      ps: "2000",
      ecu: "0.4",
      factor: "0.5",
    },
    {
      id: 1,
      Setting: "40°C cotton wash",
      lup: "1000",
      cts: "40",
      ps: "2000",
      ecu: "0.5",
      factor: "0.5",
    },
    {
      id: 2,
      Setting: "90°C cotton wash",
      lup: "1000",
      cts: "40",
      ps: "2000",
      ecu: "1.2",
      factor: "0.5",
    },
  ],
  temp_rows_10_3: [
    {
      id: 0,
      noe: 10,
      wea: 500,
      wee: 20,
      tlu: 300000,
      epu: 0.3,
    },
  ],
  temp_rows_11_1: [
    {
      id: 0,
      mwac: "1000000",
      wt: "Landfill",
      pwp: "90",
      factor: "0.3",
    },
    {
      id: 1,
      mwac: "1000000",
      wt: "Incinerated",
      pwp: "10",
      factor: "1",
    },
    {
      id: 2,
      mwac: "1000000",
      wt: "Recycled",
      pwp: 0,
      factor: 0,
    },
  ],
  temp_rows_12_1: [
    {
      id: 0,
      factory: "Factory 1",
      emission: "900000",
      space: "5000",
    },
    {
      id: 1,
      factory: "Factory 2",
      emission: "900000",
      space: "10000",
    },
  ],
  temp_rows_13_1: [
    { franchise: "1", scope1: 100000, scope2: 20000 },
    { franchise: "2", scope1: 25000, scope2: 10000 },
    { franchise: "3", scope1: 30000, scope2: 10000 },
    { franchise: "4", scope1: 90000, scope2: 30000 },
    { franchise: "5", scope1: 30000, scope2: 10000 },
    { franchise: "6", scope1: 40000, scope2: 10000 },
    { franchise: "7", scope1: 60000, scope2: 20000 },
    { franchise: "8", scope1: 10000, scope2: 90000 },
  ],
  temp_rows_13_1_1: [
    {
      id: 0,
      Franchises: "1",
      scope1: "100000",
      scope2: "20000",
    },
    {
      id: 1,
      Franchises: "2",
      scope1: "25000",
      scope2: "10000",
    },
    {
      id: 2,
      Franchises: "3",
      scope1: "30000",
      scope2: "10000",
    },
    {
      id: 3,
      Franchises: "4",
      scope1: "90000",
      scope2: "30000",
    },
    {
      id: 4,
      Franchises: "5",
      scope1: "30000",
      scope2: "10000",
    },
    {
      id: 5,
      Franchises: "6",
      scope1: "40000",
      scope2: "10000",
    },
    {
      id: 6,
      Franchises: "7",
      scope1: "60000",
      scope2: "20000",
    },
    {
      id: 7,
      Franchises: "8",
      scope1: "10000",
      scope2: "90000",
    },
  ],
  temp_rows_13_2: [
    {
      id: 0,
      Franchisee: "1",
      Type: "Food outlet",
      sa: "100",
      factor: "300",
    },
    {
      id: 1,
      Franchisee: "2",
      Type: "Food outlet",
      sa: "150",
      factor: "300",
    },
    {
      id: 2,
      Franchisee: "3",
      Type: "Clothing outlet",
      sa: "400",
      factor: "100",
    },
    {
      id: 3,
      Franchisee: "4",
      Type: "Clothing outlet",
      sa: "700",
      factor: "100",
    },
    {
      id: 4,
      Franchisee: "5",
      Type: "Clothing outlet",
      sa: "500",
      factor: "100",
    },
  ],
  temp_rows_14_1: [
    {
      id: 0,
      Investment: "1",
      Type: "Equity Investment in subsidiary",
      scope12: "100000",
      share: "25",
    },
    {
      id: 1,
      Investment: "2",
      Type: "Equity Investment in subsidiary",
      scope12: "180000",
      share: "25",
    },
    {
      id: 2,
      Investment: "3",
      Type: "Equity investment in joint venture",
      scope12: "1000000",
      share: "25",
    },
    {
      id: 3,
      Investment: "4",
      Type: "Equity investment in joint venture",
      scope12: "60000",
      share: "25",
    },
  ],
  temp_rows_14_2: [
    {
      id: 0,
      IC: "1",
      RIC: "300000",
      RCS: "5",
      ICS: "Telecommunication",
      ICR: "100",
      factor: "0.6",
    },
    {
      id: 1,
      IC: "2",
      RIC: "750000",
      RCS: "15",
      ICS: "Pharmaceutical",
      ICR: "100",
      factor: "0.5",
    },
    {
      id: 2,
      IC: "3",
      RIC: "115000",
      RCS: "20",
      ICS: "Energy generation",
      ICR: "100",
      factor: "3",
    },
    {
      id: 3,
      IC: "4",
      RIC: "550000",
      RCS: "10",
      ICS: "Energy generation",
      ICR: "60",
      factor: "2",
    },
    {
      id: 4,
      IC: "5",
      RIC: "550000",
      RCS: "10",
      ICS: "Apparel",
      ICR: "40",
      factor: "1.5",
    },
  ],
  temp_rows_1_15: [
    {
      id: 0,
      Country: "Australia",
      EP: "50000",
      DHP: 0,
      Country1: "Australia",
      UEF: "0.12",
      CEF: "0.8",
      TDR: "10",
      UEFH: 0,
    },
    {
      id: 1,
      Country: "Canada",
      EP: "60000",
      DHP: "50000",
      Country1: "Canada",
      UEF: "0.1",
      CEF: "0.4",
      TDR: "13",
      UEFH: "0.05",
    },
    {
      id: 2,
      Country: "India",
      EP: "40000",
      DHP: 0,
      Country1: "India",
      UEF: "0.15",
      CEF: "0.8",
      TDR: "15",
      UEFH: 0,
    },
    {
      id: 3,
      Country: "United States",
      EP: "550000",
      DHP: 0,
      Country1: "United States",
      UEF: "0.1",
      CEF: "0.5",
      TDR: "10",
      UEFH: 0,
    },
    {
      id: 4,
      Country: "Turkey",
      EP: "200000",
      DHP: 0,
      Country1: "Turkey",
      UEF: "0.05",
      CEF: "0.4",
      TDR: "12",
      UEFH: 0,
    },
  ],
  results: {
    result1_1: 0,
    result1_2: 0,
    result1_3: 0,
    result1_4: 0,
    result1_5: 0,
    result2_1: 0,
    result2_2: 0,
    result2_3: 0,
    result2_4: 0,
    result2_5: 0,
    result3_1: 0,
    result3_2: 0,
    result3_3: 0,
    result3_4: 0,
    result3_5: 0,
    result4_1: 0,
    result4_2: 0,
    result5_1: 0,
    result15_1: 0,
    resutl15_2: 0,
    result6_1: 0,
    result6_2: 0,
    result7_1: 0,
    result8_1: 0,
    result9_1: 0,
    result9_2: 0,
    result10_1: 0,
    result10_2: 0,
    result10_3: 0,
    result11_1: 0,
    result12_1: 0,
    result13_1: 0,
    result13_2: 0,
    result14_1: 0,
    result14_2: 0,
  },
  selectedCategoryMethods: {
    category1: "0",
    category2: "0",
    category4: "0",
    category5: "0",
    category7: "0",
    category10: "0",
    category11: "0",
    category14: "0",
    category15: "0",
  },
};
