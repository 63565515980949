import { getAuthorizationToken } from "../utils/constants";
import axios from "axios";

export const readFile = (formData) => {
  return axios.post(`${process.env.REACT_APP_SERVER_URL}/read-file`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: getAuthorizationToken(),
    },
  });
};

export const getCompaniesInvestmentData = (companyNames) => {
  return axios.post(
    `${process.env.REACT_APP_SERVER_URL}/socialEmission/companies`,
    { companyNames: companyNames },
    {
      headers: {
        Authorization: getAuthorizationToken(),
      },
    },
  );
};

export const getAllJobsData = () => {
  return axios.get(`${process.env.REACT_APP_SERVER_URL}/jobs/all`, {
    headers: {
      Authorization: getAuthorizationToken(),
    },
  });
};

export const getScopes = () => {
  return axios.get(`${process.env.REACT_APP_SERVER_URL}/load-scope`, {
    headers: {
      Authorization: getAuthorizationToken(),
    },
  });
};
