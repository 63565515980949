import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setisEmailVerified, setToken } from "../store/slices/userSlice";

const VerifyEmail = ({ sideBarFlag, setSideBarFlag }) => {
  const navigate = useNavigate();
  const dispacth = useDispatch();

  const cookies = new Cookies();
  const handleVerification = (code) => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/verify`, { token: code })
      .then((res) => {
        if (res.status !== 200) {
          cookies.set("token", null, { path: "/" });
        } else {
          localStorage.setItem("isLinkedVerified", true);
          localStorage.setItem("email", res.data.email);
          dispacth(setisEmailVerified({ isEmailVerified: true }));
          dispacth(setToken({ token: res?.data?.accessToken }));
          cookies.set("token", res?.data?.accessToken, { path: "/" });
          navigate("/calculation");
        }
      });
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("token");
    if (code) handleVerification(code);
  }, []);

  return (
    <div className="ContactUsPage" onClick={() => setSideBarFlag(false)}>
      <Header sideBarFlag={sideBarFlag} setSideBarFlag={setSideBarFlag} />
      <div className="main">
        <div className="card-container">
          <div className="card">
            <div className="main">
              <div style={{ textAlign: "center", marginTop: "50px" }}>
                <h1>Thank you for verifying your email!</h1>
                <p>You can now access all features of our service.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div id="snackbar">Job has been Created will run in 24 hours!</div>
    </div>
  );
};

export default VerifyEmail;
