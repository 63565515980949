import { createSlice } from "@reduxjs/toolkit";
import { seedData } from "../../constants/calculationSeed";

const initialState = { ...seedData };

const calculationSlice = createSlice({
  name: "calculation",
  initialState,
  reducers: {
    setCalculation: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setCalculationResult: (state, action) => {
      state.results[action.payload.key] = action.payload.value;
    },
    setCategoryMethodNumber: (state, action) => {
      state.selectedCategoryMethods[action.payload.key] = action.payload.value;
    },
    resetCalculation: (state) => {
      state.results = initialState.results;
    },
    resetCategoryMethodNumber: (state) => {
      state.selectedCategoryMethods = initialState.selectedCategoryMethods;
    },
    reset: () => initialState,
  },
});

export const {
  setCalculation,
  setCalculationResult,
  setCategoryMethodNumber,
  resetCalculation,
  resetCategoryMethodNumber,
  reset,
} = calculationSlice.actions;

export default calculationSlice.reducer;
