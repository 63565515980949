import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabId: 0,
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tabId = action.payload.tabId;
    },
    reset: () => initialState,
  },
});

export const { setTab, reset } = navigationSlice.actions;

export default navigationSlice.reducer;
