import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import { useDispatch } from "react-redux";
import useRTKStore from "../../store/useRTKStore";
import { setCalculation } from "../../store/slices/calculation";
function UseIndirect({ onChange, dataset1 }) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_10_2 } = useRTKStore("calculation", ["temp_rows_10_2"]);
  const rows = JSON.parse(JSON.stringify(temp_rows_10_2));

  useEffect(() => {
    if (!dataset1 || Object.keys(dataset1).length == 0) return;

    const k = [].concat([], rows);
    rows.forEach((d, idx) => {
      if (dataset1.hasOwnProperty(k[idx].Setting)) {
        k[idx].factor = dataset1[k[idx].Setting];
      } else {
        k[idx].factor = k[idx].factor;
      }
    });
    setCalculationData("temp_rows_10_2", k);
  }, [dataset1]);

  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };
  currentRef.current = false;

  useEffect(() => {
    let sum = 0;
    rows.forEach((row) => {
      sum +=
        (parseFloat(row.lup) *
          parseFloat(row.cts) *
          parseFloat(row.ps) *
          parseFloat(row.ecu) *
          parseFloat(row.factor)) /
        100;
    });
    onChange("result10_2", sum);
  }, [rows]);

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div className="container">
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Usage temperature setting</th>
              <th>Lifetime uses per product (washes)</th>
              <th>Consumers using temperature setting (percent)</th>
              <th>Products sold</th>
              <th>Electricity consumed per use (KWh)</th>
              <th>Emission factor (KgCO2e/KWh)</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.Setting}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.Setting = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_2", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.lup}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.lup = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_2", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.cts}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.cts = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_2", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.ps}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.ps = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_2", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.ecu}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.ecu = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_2", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.factor === 0 ? "NOT FOUND" : row.factor}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.factor = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_2", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        Setting: "",
                        lup: "",
                        cts: "",
                        ps: "",
                        ecu: "",
                        factor: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_10_2", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_10_2", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UseIndirect;
