import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import { useDispatch } from "react-redux";
import useRTKStore from "../../store/useRTKStore";
import { setCalculation } from "../../store/slices/calculation";
function EmployeeDistance({ onChange }) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_6_1 } = useRTKStore("calculation", ["temp_rows_6_1"]);
  const rows = JSON.parse(JSON.stringify(temp_rows_6_1));

  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };

  currentRef.current = false;

  useEffect(() => {
    let sum = 0;
    rows.forEach((row) => {
      sum +=
        (parseFloat(row.rc) * parseFloat(row.owd) * parseFloat(row.ref) +
          parseFloat(row.cc) * parseFloat(row.cef) * parseFloat(row.odc)) *
        2 *
        48;
    });
    onChange("result6_1", sum);
  }, [rows]);

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div className="container">
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Employee</th>
              <th> Rail commute (times per week)</th>
              <th>One way distance by rail(km)</th>
              <th>Rail emission factor</th>
              <th>Car commute </th>
              <th>Car emission factor</th>
              <th>One way distance by car</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.Employee}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.Employee = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_6_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.rc}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.rc = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_6_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.owd}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.owd = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_6_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.ref}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.ref = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_6_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.cc}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.cc = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_6_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.cef}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.cef = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_6_1", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.odc}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.odc = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_6_1", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        Employee: "",
                        rc: "",
                        owd: "",
                        ref: "",
                        cc: "",
                        cef: "",
                        odc: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_6_1", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_6_1", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EmployeeDistance;
