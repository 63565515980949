import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import { useDispatch } from "react-redux";
import useRTKStore from "../../store/useRTKStore";
import { setCalculation } from "../../store/slices/calculation";
function InvestmentAverage({ onChange, setCompaniesNames, investmentData }) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_14_2 } = useRTKStore("calculation", ["temp_rows_14_2"]);
  const rows = JSON.parse(JSON.stringify(temp_rows_14_2));
  currentRef.current = false;

  useEffect(() => {
    let sum = 0;
    rows.forEach((row) => {
      sum +=
        (parseFloat(row.RIC) *
          parseFloat(row.RCS) *
          parseFloat(row.ICR) *
          parseFloat(row.factor)) /
        10000;
    });
    onChange("result14_2", sum);
  }, [rows]);

  useEffect(() => {
    const k = [].concat([], rows);

    k.forEach((dat, idx) => {
      dat.factor = investmentData[dat.IC.toLowerCase()]?.scope12 || dat.factor;
    });
    setCalculationData("temp_rows_14_2", k);
  }, [investmentData]);

  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div className="container">
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Investee company</th>
              <th>Revenue of investee company ($)</th>
              <th>Reporting company’s share of equity (percent)</th>
              <th>Investee company’s sector(s) of operation</th>
              <th>Investee company’s revenue in sector (percent)</th>
              <th>
                Scope 1 and scope 2 emission factor of sector (kg CO2e/$
                revenue)
              </th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.IC}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.IC = e.target.value;
                        }
                        return i;
                      });
                      const newNames = newData.map((row) => row.IC);
                      setCompaniesNames(newNames);
                      setCalculationData("temp_rows_14_2", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.RIC}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.RIC = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_14_2", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.RCS}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.RCS = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_14_2", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.ICS}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.ICS = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_14_2", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.ICR}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.ICR = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_14_2", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.factor}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.factor = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_14_2", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        IC: "",
                        RIC: "",
                        RCS: "",
                        ICS: "",
                        ICR: "",
                        factor: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_14_2", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_14_2", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InvestmentAverage;
