import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import useRTKStore from "../../store/useRTKStore";
import { useDispatch } from "react-redux";
import { setCalculation } from "../../store/slices/calculation";
function ProcessingSite({ onChange }) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_9_1_1, temp_rows_9_1_2 } = useRTKStore("calculation", [
    "temp_rows_9_1_1",
    "temp_rows_9_1_2",
  ]);
  const rows = JSON.parse(JSON.stringify(temp_rows_9_1_1));
  const rows1 = JSON.parse(JSON.stringify(temp_rows_9_1_2));

  currentRef.current = false;
  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };

  useEffect(() => {
    let sum = 0;
    let sum1 = 0;
    rows.forEach((row) => {
      sum += parseFloat(row.amount) * parseFloat(row.factor);
    });
    rows1.forEach((row1) => {
      sum1 += parseFloat(row1.amount) * parseFloat(row1.factor);
    });
    onChange("result9_1", sum + sum1);
  }, [rows, rows1]);

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div className="container">
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Fuel and electricity consumed</th>
              <th>Amount (kWh)</th>
              <th>Emission factor (kg CO2e/kWh)</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.consumed}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.consumed = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_9_1_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.amount}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.amount = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_9_1_1", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.factor === 0 ? "NOT FOUND" : row.factor}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.factor = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_9_1_1", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        consumed: "",
                        amount: "",
                        factor: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_9_1_1", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_9_1_1", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <th>Waste</th>
              <th>Amount (kg) </th>
              <th> Emission factor (kg CO2e/kg waste)</th>
            </tr>
            {rows1.map((row1) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row1.waste}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows1.map((i) => {
                        if (i.id === row1.id) {
                          i.waste = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_9_1_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row1.amount}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows1.map((i) => {
                        if (i.id === row1.id) {
                          i.amount = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_9_1_1", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row1.factor === 0 ? "NOT FOUND" : row1.factor}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows1.map((i) => {
                        if (i.id === row1.id) {
                          i.factor = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_9_1_1", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        waste: "",
                        amount: "",
                        factor: "",
                      };
                      let pos =
                        rows1.indexOf(
                          rows1.find((item) => item.id === row1.id),
                        ) + 1;

                      const newData = [].concat(
                        rows1.slice(0, pos),
                        newRow,
                        rows1.slice(pos),
                      );
                      setCalculationData("temp_rows_9_1_1", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows1.indexOf(
                        rows1.find((item) => item.id == row1.id),
                      );
                      const newData = [].concat(
                        rows1.slice(0, pos),
                        rows1.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_9_1_1", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ProcessingSite;
