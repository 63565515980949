import { configureStore } from "@reduxjs/toolkit";
import { useSelector as useReduxSelector } from "react-redux";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { userSlice, calculationSlice, navigationSlice } from "./slices";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [userSlice, calculationSlice, navigationSlice],
};

const rootReducer = combineReducers({
  user: userSlice,
  calculation: calculationSlice,
  navigation: navigationSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const useSelector = useReduxSelector;

export const persistor = persistStore(store);
