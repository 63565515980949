import React, { useEffect, useState, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import useRTKStore from "../../store/useRTKStore";
import { useDispatch } from "react-redux";
import { setCalculation } from "../../store/slices/calculation";

function CapitalGoodHybrid1({ onChange, dataset1 }) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_2_2_1_1, temp_rows_2_2_1_2, temp_rows_2_2_1_3 } =
    useRTKStore("calculation", [
      "temp_rows_2_2_1_1",
      "temp_rows_2_2_1_2",
      "temp_rows_2_2_1_3",
    ]);
  const rows = JSON.parse(JSON.stringify(temp_rows_2_2_1_1));
  const rows2 = JSON.parse(JSON.stringify(temp_rows_2_2_1_2));
  const rows3 = JSON.parse(JSON.stringify(temp_rows_2_2_1_3));

  currentRef.current = false;
  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };

  useEffect(() => {
    if (!dataset1 || Object.keys(dataset1).length == 0) return;

    const k = [].concat([], rows);
    rows.forEach((d, idx) => {
      if (dataset1.hasOwnProperty(k[idx].gool)) {
        k[idx].ssef = dataset1[k[idx].gool];
      } else {
        k[idx].ssef = k[idx].ssef;
      }
    });
    setCalculationData("temp_rows_2_2_1_1", k);

    const k2 = [].concat([], rows2);
    rows2.forEach((d, idx) => {
      if (dataset1.hasOwnProperty(k2[idx].gool)) {
        k2[idx].ssef = dataset1[k2[idx].gool];
      } else {
        k2[idx].ssef = k2[idx].ssef;
      }
    });
    setCalculationData("temp_rows_2_2_1_2", k2);

    const k3 = [].concat([], rows3);
    rows3.forEach((d, idx) => {
      if (dataset1.hasOwnProperty(k3[idx].gool)) {
        k3[idx].ssef = dataset1[k3[idx].gool];
      } else {
        k3[idx].ssef = k3[idx].ssef;
      }
    });
    setCalculationData("temp_rows_2_2_1_3", k3);
  }, [dataset1]);

  useEffect(() => {
    let sum = 0;
    let sum2 = 0;
    let sum3 = 0;
    rows.forEach((row) => {
      sum += parseFloat(row.qp) * parseFloat(row.ssef) || 0;
    });
    rows2.forEach((row2) => {
      sum2 += parseFloat(row2.qp) * parseFloat(row2.ssef);
    });
    rows3.forEach((row3) => {
      sum3 += parseFloat(row3.qp) * parseFloat(row3.ssef);
    });
    onChange("result2_3", sum + sum2 + sum3);
  }, [rows, rows2, rows3]);

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div>
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th></th>
              <th>Amount (kWh)</th>
              <th>Emission factor (kg CO2e/kWh)</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.gool}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.gool = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_2_1_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.qp}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.qp = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_2_1_1", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.ssef === 0 ? "Not Found" : row.ssef}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.ssef = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_2_1_1", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        gool: "",
                        qp: "",
                        ssef: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_2_2_1_1", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_2_2_1_1", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <th></th>
              <th>Amount (kg)</th>
              <th>Emission factor (kg CO2e/kg of waste sent to landfill)</th>
            </tr>
            {rows3.map((row3) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row3.gool}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows3.map((i) => {
                        if (i.id === row3.id) {
                          i.gool = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_2_1_3", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row3.qp}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows3.map((i) => {
                        if (i.id === row3.id) {
                          i.qp = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_2_1_3", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row3.ssef === 0 ? "Not Found" : row3.ssef}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows3.map((i) => {
                        if (i.id === row3.id) {
                          i.ssef = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_2_1_3", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        gool: "",
                        qp: "",
                        ssef: "",
                      };
                      let pos =
                        rows3.indexOf(
                          rows3.find((item) => item.id === row3.id),
                        ) + 1;

                      const newData = [].concat(
                        rows3.slice(0, pos),
                        newRow,
                        rows3.slice(pos),
                      );
                      setCalculationData("temp_rows_2_2_1_3", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows3.indexOf(
                        rows3.find((item) => item.id == row3.id),
                      );
                      const newData = [].concat(
                        rows3.slice(0, pos),
                        rows3.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_2_2_1_3", newData);
                    }}
                  />
                </td>
              </tr>
            ))}

            <tr>
              <th></th>
              <th>Number of t-shirts purchased from supplier B</th>
              <th>
                Cradle-to-gate process emission factor (kg CO2e/per t-shirt)
                (excluding scope 1 and 2 emissions and emissions from waste
                associated with final producer)
              </th>
            </tr>

            {rows2.map((row2) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row2.gool}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows2.map((i) => {
                        if (i.id === row2.id) {
                          i.gool = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_2_1_2", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row2.qp}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows2.map((i) => {
                        if (i.id === row2.id) {
                          i.qp = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_2_1_2", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row2.ssef === 0 ? "Not Found" : row2.ssef}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows2.map((i) => {
                        if (i.id === row2.id) {
                          i.ssef = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_2_2_1_2", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        gool: "",
                        qp: "",
                        ssef: "",
                      };
                      let pos =
                        rows2.indexOf(
                          rows2.find((item) => item.id === row2.id),
                        ) + 1;

                      const newData = [].concat(
                        rows2.slice(0, pos),
                        newRow,
                        rows2.slice(pos),
                      );
                      setCalculationData("temp_rows_2_2_1_2", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows2.indexOf(
                        rows2.find((item) => item.id == row2.id),
                      );
                      const newData = [].concat(
                        rows2.slice(0, pos),
                        rows2.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_2_2_1_2", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CapitalGoodHybrid1;
