import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
// import { ReactComponent as HomeIcon } from '../assets/svg/home.svg'
// import { ReactComponent as PhoneIcon } from '../assets/svg/phone.svg'
// import { ReactComponent as MailIcon } from '../assets/svg/mail.svg'
// import { ReactComponent as LinkIcon } from '../assets/svg/link.svg'
import Footer from "../components/Footer";
import { useDispatch } from "react-redux";
import { setTab } from "../store/slices/navigation";

function VideoPage({ sideBarFlag, setSideBarFlag }) {
  const dispatch = useDispatch();
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayClick = () => {
    setIsPlaying(true);
    videoRef.current.play();
  };
  useEffect(() => {
    dispatch(setTab({ tabId: 3 }));
  }, []);
  return (
    <div className="VideoPage" onClick={() => setSideBarFlag(false)}>
      <Header sideBarFlag={sideBarFlag} setSideBarFlag={setSideBarFlag} />
      <div className="main">
        <div className="card-container">
          <div className="card">
            <div className="main video-wrapper">
            {!isPlaying && (
                <button className="play-button" onClick={handlePlayClick}>
                  ▶
                </button>
              )}
              <video ref={videoRef} width="80%" height="80%" controls controlsList="nodownload">
                <source src="/VeriCO2.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default VideoPage;
