import React, { useRef } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "../App.scss";
import useRTKStore from "../store/useRTKStore";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

function DisplayPage() {
  const { selectedCategoryMethods, results } = useRTKStore("calculation", [
    "selectedCategoryMethods",
    "results",
  ]);
  const {
    category1,
    category2,
    category4,
    category5,
    category7,
    category10,
    category11,
    category14,
    category15,
  } = selectedCategoryMethods;
  const {
    result1_1,
    result1_2,
    result1_3,
    result1_4,
    result2_1,
    result2_2,
    result2_3,
    result2_4,
    result3_1,
    result3_2,
    result3_3,
    result4_1,
    result4_2,
    result5_1,
    result15_1,
    result6_1,
    result6_2,
    result7_1,
    result8_1,
    result9_1,
    result9_2,
    result10_1,
    result10_2,
    result11_1,
    result12_1,
    result13_1,
    result13_2,
    result14_1,
    result14_2,
  } = results;

  console.log(
    category1,
    category2,
    category4,
    category5,
    category7,
    category10,
    category11,
    category14,
    category15,
  );
  const Chart1_1 = result1_1 / 1000;
  const Chart1_2 = result1_2 / 1000;
  const Chart1_3 = result1_3 / 1000;
  const Chart1_4 = result1_4 / 1000;
  const Chart2_1 = result2_1 / 1000;
  const Chart2_2 = result2_2 / 1000;
  const Chart2_3 = result2_3 / 1000;
  const Chart2_4 = result2_4 / 1000;
  const Chart1_15 = result15_1 / 1000;
  const Chart3_1 = result3_1 / 1000;
  const Chart3_2 = result3_2 / 1000;
  const Chart3_3 = result3_3 / 1000;
  const Chart4_1 = result4_1 / 1000;
  const Chart4_2 = result4_2 / 1000;
  const Chart5_1 = result5_1 / 1000;
  const Chart6_1 = result6_1 / 1000;
  const Chart6_2 = result6_2 / 1000;
  const Chart7_1 = result7_1 / 1000;
  const Chart8_1 = result8_1 / 1000;
  const Chart9_1 = result9_1 / 1000;
  const Chart9_2 = result9_2 / 1000;
  const Chart10_1 = result10_1 / 1000;
  const Chart10_2 = result10_2 / 1000;
  const Chart11_1 = result11_1 / 1000;
  const Chart12_1 = result12_1 / 1000;
  const Chart13_1 = result13_1 / 1000;
  const Chart13_2 = result13_2 / 1000;
  const Chart14_1 = result14_1 / 1000;
  const Chart14_2 = result14_2 / 1000;
  const displaycategory1 = () => {
    if (category1 === "0") return Chart1_1;
    if (category1 === "1") return Chart1_2;
    if (category1 === "2") return Chart1_3;
    if (category1 === "3") return Chart1_4;
  };
  const displaycategory2 = () => {
    if (category2 === "0") return Chart2_1;
    if (category2 === "1") return Chart2_2;
    if (category2 === "2") return Chart2_3;
    if (category2 === "3") return Chart2_4;
  };
  const displaycategory4 = () => {
    if (category4 === "0") return Chart3_1;
    if (category4 === "1") return Chart3_2;
    if (category4 === "2") return Chart3_3;
  };
  const displaycategory5 = () => {
    if (category5 === "0") return Chart4_1;
    if (category5 === "1") return Chart4_2;
  };
  const displaycategory7 = () => {
    if (category7 === "0") return Chart6_1;
    if (category7 === "1") return Chart6_2;
  };
  const displaycategory10 = () => {
    if (category10 === "0") return Chart9_1;
    if (category10 === "1") return Chart9_2;
  };
  const displaycategory11 = () => {
    if (category11 === "0") return Chart10_1;
    if (category11 === "1") return Chart10_2;
  };
  const displaycategory14 = () => {
    if (category14 === "0") return Chart13_1;
    if (category14 === "1") return Chart13_2;
  };
  const displaycategory15 = () => {
    if (category15 === "0") return Chart14_1;
    if (category15 === "1") return Chart14_2;
  };

  const displaymethod1 = () => {
    if (category1 === "0") return "Supplier-specific method";
    if (category1 === "1") return "Hybrid method";
    if (category1 === "2") return "Average-data method";
    if (category1 === "3") return "Spend-based method";
  };
  const displaymethod2 = () => {
    if (category2 === "0") return "Supplier-specific method";
    if (category2 === "1") return "Hybrid method";
    if (category2 === "2") return "Average-product method";
    if (category2 === "3") return "Average spend-based method";
  };
  const displaymethod4 = () => {
    if (category4 === "0") return "Fuel-based method";
    if (category4 === "1") return "Distance-based method";
    if (category4 === "2") return "Spend-based method";
  };
  const displaymethod5 = () => {
    if (category5 === "0") return "Waste-type-specific method";
    if (category5 === "1") return "Average-data method";
  };
  const displaymethod7 = () => {
    if (category7 === "0") return "Distance-based method";
    if (category7 === "1") return "Average-data method";
  };
  const displaymethod10 = () => {
    if (category10 === "0") return "Site-specific method";
    if (category10 === "1") return "Average-data method";
  };
  const displaymethod11 = () => {
    if (category11 === "0") return "Direct use-phase emissions";
    if (category11 === "1") return "Indirect use-phase emissions";
  };
  const displaymethod14 = () => {
    if (category14 === "0") return "Franchise-specific method";
    if (category14 === "1") return "Average-data method";
  };
  const displaymethod15 = () => {
    if (category15 === "0") return "Investment-specific method";
    if (category15 === "1") return "Average-data method";
  };

  const labels = [
    "Purchased Goods and Services",
    "Capital Goods",
    "Fuel- and Energy-Related Activities",
    "Upstream Transportation and Distribution",
    "Waste Generated in Operations",
    "Business Travel",
    "Employee Commuting",
    "Upstream Leased Assets",
    "Downstream Transportation and Distribution",
    "Processing of Sold Products",
    "Use of Sold Products",
    "End-of-Life Treatment of Sold Products",
    "Downstream Leased Assets",
    "Franchises",
    "Investments",
  ];
  const Numbers_list = [
    displaycategory1(),
    displaycategory2(),
    Chart1_15,
    displaycategory4(),
    displaycategory5(),
    Chart5_1,
    displaycategory7(),
    Chart7_1,
    Chart8_1,
    displaycategory10(),
    displaycategory11(),
    Chart11_1,
    Chart12_1,
    displaycategory14(),
    displaycategory15(),
  ].map((i) => i * 1000);
  const maxNumber = parseInt(
    (displaycategory1() +
      displaycategory2() +
      Chart1_15 +
      displaycategory4() +
      displaycategory5() +
      Chart5_1 +
      displaycategory7() +
      Chart7_1 +
      Chart8_1 +
      displaycategory10() +
      displaycategory11() +
      Chart11_1 +
      Chart12_1 +
      displaycategory14() +
      displaycategory15()) *
      1000,
  ).toLocaleString();
  const pdfRef = useRef();
  const downloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio,
      );
      pdf.save("Scope 3 Emissions Output.pdf");
    });
  };
  const data = {
    labels,
    datasets: [
      {
        label: "Category Scope 3 emissions (tCO2-e)",
        data: Numbers_list,
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: ["Total Scope 3 emissions: " + maxNumber + "t CO2"],
        font: {
          size: 20,
          weight: "bold",
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "tCO2-e",
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
    },
  };
  return (
    <div className="DisplayPage">
      <Header />
      <div className="container" ref={pdfRef}>
        <div className="cards">
          <div className="card">
            <div className="items">
              <div className="item">
                <span>Emissions</span>
                <span>(t co2e)</span>
              </div>
              <div className="item">
                <span>TOTAL EMISSIONS OF SCOPE3</span>
                <span>(2023/10/11/12:00)</span>
                <span>{maxNumber} t CO2e</span>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="title">Emission analysis display</div>
            <div className="button" onClick={downloadPDF}>
              Download PDF
            </div>
            <div className="chart">
              {/* <img src={exampleChart} alt="Example Chart" className='example-chart' /> */}
              <Bar options={options} data={data} />
            </div>
          </div>
          <div className="card">
            <span className="title">Result Data</span>
            <div className="table-container">
              <table>
                <tbody>
                  <tr>
                    <th>Category</th>
                    <th>Method</th>
                    <th>Total Emission of Scope3 (t CO2)</th>
                  </tr>
                  <tr>
                    <td>Purchased Goods and Services</td>
                    <td>{displaymethod1()}</td>
                    <td>{displaycategory1()?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Capital Goods</td>
                    <td>{displaymethod2()}</td>
                    <td>{displaycategory2()?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>
                      Fuel- and Energy-Related Activities Not Included in Scope
                      1 or Scope 2
                    </td>
                    <td>Transmission and distribution losses method</td>
                    <td>{Chart1_15.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Upstream Transportation and Distribution</td>
                    <td>{displaymethod4()}</td>
                    <td>{displaycategory4()?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Waste Generated in Operations</td>
                    <td>{displaymethod5()}</td>
                    <td>{displaycategory5()?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Business Travel</td>
                    <td>Distance-based method</td>
                    <td>{Chart5_1.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Employee Commuting</td>
                    <td>{displaymethod7()}</td>
                    <td>{displaycategory7()?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Upstream Leased Assets</td>
                    <td>Asset-specific method</td>
                    <td>{Chart7_1.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Downstream Transportation and Distribution</td>
                    <td>Downstream method</td>
                    <td>{Chart8_1.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Processing of Sold Products</td>
                    <td>{displaymethod10()}</td>
                    <td>{displaycategory10()?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Use of Sold Products</td>
                    <td>{displaymethod11()}</td>
                    <td>{displaycategory11()?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>End-of-Life Treatment of Sold Products</td>
                    <td>Waste-type-specific method</td>
                    <td>{Chart11_1.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Downstream Leased Assets</td>
                    <td>Downstream Leased Assets method</td>
                    <td>{Chart12_1.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Franchises</td>
                    <td>{displaymethod14()}</td>
                    <td>{displaycategory14()?.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Investments</td>
                    <td>{displaymethod15()}</td>
                    <td>{displaycategory15()?.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DisplayPage;
