import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const EmailVerification = ({ sideBarFlag, setSideBarFlag }) => {
  return (
    <div className="ContactUsPage" onClick={() => setSideBarFlag(false)}>
      <Header sideBarFlag={sideBarFlag} setSideBarFlag={setSideBarFlag} />
      <div className="main">
        <div className="card-container">
          <div className="card">
            <div className="main">
              <div style={{ textAlign: "center", marginTop: "50px" }}>
                <div>
                  <h1>Email Verification Required</h1>
                  <p>
                    We have sent a verification Mail on you Email. Please verify
                    your email to continue using our services.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div id="snackbar">Job has been Created will run in 24 hours!</div>
    </div>
  );
};

export default EmailVerification;
