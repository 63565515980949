import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import { useDispatch } from "react-redux";
import useRTKStore from "../../store/useRTKStore";
import { setCalculation } from "../../store/slices/calculation";

function SoldImmidiate({ onChange }) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_10_3 } = useRTKStore("calculation", ["temp_rows_10_3"]);
  const rows = JSON.parse(JSON.stringify(temp_rows_10_3));

  currentRef.current = false;

  useEffect(() => {
    let sum = 0;
    rows.forEach((row) => {
      sum +=
        parseFloat(row.noe) *
        parseFloat(row.tlu) *
        parseFloat(row.epu) *
        (parseFloat(row.wee) / parseFloat(row.wea));
    });
    onChange("result10_3", sum);
  }, [rows]);

  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div className="container">
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Number of engines Sold</th>
              <th>Weight of each airplane (tonnes)</th>
              <th>Weight of each Engine (tonnes)</th>
              <th>
                Total lifetime uses of final products (km flown by airplane)
              </th>
              <th>Emissions per use of final product (kg CO 2 e/km flown)</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.noe}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.noe = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_3", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.wea}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.wea = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_3", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.wee}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.wee = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_3", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.tlu}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.tlu = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_3", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.epu === 0 ? "NOT FOUND" : row.epu}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.epu = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_3", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        Setting: "",
                        lup: "",
                        cts: "",
                        ps: "",
                        ecu: "",
                        factor: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_10_3", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_10_3", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default SoldImmidiate;
