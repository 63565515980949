import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import { useDispatch } from "react-redux";
import useRTKStore from "../../store/useRTKStore";
import { setCalculation } from "../../store/slices/calculation";
function InvestmentSpecific({
  onChange,
  dataset2,
  dataset,
  setCompaniesNames,
  investmentData,
}) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_14_1 } = useRTKStore("calculation", ["temp_rows_14_1"]);
  const rows = JSON.parse(JSON.stringify(temp_rows_14_1));
  currentRef.current = false;

  useEffect(() => {
    const k = [].concat([], rows);
    k.forEach((dat, idx) => {
      dat.scope12 =
        investmentData[dat.Investment.toLowerCase()]?.scope12 || dat.scope12;
    });
    setCalculationData("temp_rows_14_1", k);
  }, [investmentData]);

  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };

  useEffect(() => {
    let sum = 0;
    rows.forEach((row) => {
      sum += (parseFloat(row.scope12) * parseFloat(row.share)) / 100;
    });
    onChange("result14_1", sum);
  }, [rows]);

  return (
    <div className="container">
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Investment</th>
              <th>Investment type</th>
              <th>
                Scope 1 and scope 2 emissions of investee company in reporting
                year (Kg CO2e)
              </th>
              <th>Reporting company’s share of equity (percent)</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.Investment}
                    className="Input_form"
                    onChange={(e) => {
                      console.log("changing 14_1_investment");

                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.Investment = e.target.value;
                        }
                        return i;
                      });
                      const newNames = rows.map((row) => row.Investment);
                      setCompaniesNames(newNames);
                      setCalculationData("temp_rows_14_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.Type}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.Type = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_14_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.scope12 === 0 ? "NOT FOUND" : row.scope12}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.scope12 = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_14_1", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.share}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.share = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_14_1", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        Investment: "",
                        Type: "",
                        scope12: "",
                        share: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_14_1", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_14_1", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InvestmentSpecific;
