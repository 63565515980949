import React, { useState, useEffect } from 'react'
import signupLeft from '../assets/images/signup-left.png'
import mainLogo from '../assets/images/header-logo.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from "universal-cookie";
import { handleLoginApi } from "../api/auth.api";
import { useDispatch } from "react-redux";
import { setUserData } from "../store/slices/userSlice";

const linkedinOAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    process.env.REACT_APP_LINKEDIN_CALLBACK_URL
)}&scope=email%20openid`;

function SignupPage({ SERVER_URL }) {
    const navigate = useNavigate();
    const dispath = useDispatch();
    const cookies = new Cookies();

    // const [fake, setfake] = useState(true)
    // const [fake1, setfake1] = useState(true)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [consultant, setConsultant] = useState('corporate')
    // const [date, setDate] = useState('')
    const [password, setPassword] = useState('')
    const [company, setCompany] = useState('')

    const [errorMessage, setErrorMessage] = useState('');

    const handleLogin = (code) => {
        handleLoginApi(code)
          .then((res) => {
            setDataOnSuccess(res);
          })
          .catch((err) => {
            cookies.set("token", null, { path: "/" });
            setErrorMessage("Incorrect Email Address or Password.");
            navigate("/login");
          });
      };
    
      const setDataOnSuccess = (res) => {
        console.log(res.data);
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("isLinkedVerified", res.data.isEmailVerified || false);
        cookies.set("token", res?.data?.token, { path: "/" });
        setErrorMessage("");
        dispath(
          setUserData({
            email: res.data.user.email,
            user: res.data.user.name,
            companyName: res.data.user.companyName,
            phoneNumber: res.data.user.phone,
            id: res.data.user.id,
            token: res.data.token,
            isEmailVerified: res.data.user.isEmailVerified,
            isLinkedInUser: res.data.user.isLinkedInUser,
          }),
        );
        !res.data.isEmailVerified && navigate("/email-verification");
        res.data.isEmailVerified && navigate("/calculation");
      };
    
      const handleLinkedInCallback = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get("code");
        if (code) {
          handleLogin(code);
        }
      };
    
      useEffect(() => {
        return handleLinkedInCallback();
      }, []);


    return (
        // <div className='SignupPage' onClick={() => setfake1(true)}>
        <div className='SignupPage' onKeyUp={(e) => {
            if (e.key === "Enter") navigate('/login')
        }}>
            <img src={signupLeft} alt="Signup Left" className='signup-left' />
            <div className='right'>
                <div className='center'>
                    <img src={mainLogo} alt="Main Logo" className='main-logo' onClick={() => navigate('/')} />
                    <span>Please fill in the form to open an account, discuss your options, </span><span>or to receive a demo with one of our representatives.</span>
                    {/* <span>Fill the form to open an account,</span><span>discuss your options with a representative or discuss a demo</span> */}
                    <div className='input-box'>
                        <input type='text' placeholder='Full Name' value={name} onChange={(e) => setName(e.target.value)}></input>
                    </div>
                    <div className='input-box'>
                        <input type='email' placeholder='Email Address' value={email} onChange={(e) => setEmail(e.target.value)}></input>
                    </div>
                    <div className='input-box'>
                        <input type='text' placeholder='Phone Number' value={phone} onChange={(e) => setPhone(e.target.value)}></input>
                    </div>
                    <div className='input-box'>
                        {/* <input type='consultant' placeholder='Consultant' value={consultant} onChange={(e) => setConsultant(e.target.value)}></input> */}
                        <select name="Consultant" onChange={(e) => setConsultant(e.target.value)}>
                            <option value="corporate">Corporate</option>
                            <option value="consultant">Consultant</option>
                            <option value="regulatory body">Regulatory body</option>
                        </select>
                    </div>
                    {/* <div className='input-box' onMouseOver={() => setfake(false)} onMouseLeave={() => setfake(true)}>
                        <input placeholder="Date of Birth" type={fake && fake1 ? "text" : "date"}
                            value={date} onChange={(e) => setDate(e.target.value)}
                            onClick={(e) => {
                                e.stopPropagation();
                                setfake1(false)
                            }}></input>
                    </div> */}
                    <div className='input-box'>
                        <input type='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)}></input>
                    </div>
                    <div className='input-box'>
                        <input type='text' placeholder='Company Name' value={company} onChange={(e) => setCompany(e.target.value)}></input>
                    </div>
                    <span className={errorMessage === '' ? 'hidden' : 'error'}>{errorMessage}</span>
                    <div className='button' onClick={() => {
                        if (name === '') setErrorMessage('Please type Full Name.')
                        else if (email === '') setErrorMessage('Please type Email Address.')
                        else if (phone === '') setErrorMessage('Please type Phone Number.')
                        else if (consultant === '') setErrorMessage('Please type Consultant.')
                        // else if (date === '') setErrorMessage('Please choose Date of Birth.')
                        else if (password === '') setErrorMessage('Please type Password.')
                        else if (company === '') setErrorMessage('Please type Company Name.')
                        else {
                            setErrorMessage('')
                            const obj = {
                                name: name,
                                password: password,
                                email: email,
                                consultant: consultant,
                                // birthday: date,
                                phoneNumber: phone,
                                companyName: company
                            }
                            axios.post(SERVER_URL + '/signup', obj)
                                .then(res => {
                                    if (!res.data.success) setErrorMessage(res.data.message)
                                    else {
                                        setErrorMessage('')
                                        navigate('/login')
                                    }
                                })
                        }
                    }}>Sign Up</div>
                    <div class="sig">
                        <div class='ico-wrapper'>
                            <svg class="ico" width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="LinkedIn" fill="#FFFFFF">
                                        <g id="Fill-6-+-Fill-7" transform="translate(6.000000, 5.000000)">
                                            <path d="M3.44222222,0 C5.34,0 6.88,1.54111111 6.88,3.44 C6.88,5.34 5.34,6.88111111 3.44222222,6.88111111 C1.53666667,6.88111111 0,5.34 0,3.44 C0,1.54111111 1.53666667,0 3.44222222,0 L3.44222222,0 Z M0.471111111,9.48888889 L6.41,9.48888889 L6.41,28.5777778 L0.471111111,28.5777778 L0.471111111,9.48888889 Z"
                                                id="Fill-6"></path>
                                            <path d="M10,9.47333333 L15.6866667,9.47333333 L15.6866667,12.0833333 L15.7688889,12.0833333 C16.56,10.5822222 18.4955556,9 21.3811111,9 C27.3888889,9 28.4988889,12.9522222 28.4988889,18.0933333 L28.4988889,28.5622222 L22.5666667,28.5622222 L22.5666667,19.2788889 C22.5666667,17.0655556 22.5288889,14.2177778 19.4844444,14.2177778 C16.3966667,14.2177778 15.9255556,16.63 15.9255556,19.1211111 L15.9255556,28.5622222 L10,28.5622222 L10,9.47333333"
                                                id="Fill-7"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <a class="link" href={linkedinOAuthURL}>Sign in with LinkedIn</a>
                    </div>
                    <span onClick={() => navigate('/login')}>Already have an acount? <u>Login Now</u></span>
                </div>
            </div>
        </div>
    )
}

export default SignupPage