import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import { useDispatch } from "react-redux";
import useRTKStore from "../../store/useRTKStore";
import { setCalculation } from "../../store/slices/calculation";
function FranchisesSpecificTest({
  onChange,
  dataset,
  dataset2,
  setCompaniesNames,
  investmentData,
}) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_13_1_1 } = useRTKStore("calculation", ["temp_rows_13_1_1"]);
  const rows = JSON.parse(JSON.stringify(temp_rows_13_1_1));
  currentRef.current = false;

  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };

  useEffect(() => {
    const k = [].concat([], rows);
    k.forEach((dat, idx) => {
      dat.scope1 =
        investmentData[dat.Franchises.toLowerCase()]?.scope1 || dat.scope1;
      dat.scope2 =
        investmentData[dat.Franchises.toLowerCase()]?.scope2 || dat.scope2;
    });

    setCalculationData("temp_rows_13_1_1", k);
  }, [investmentData]);

  const handleOnchange = (name, index) => {
    const value = dataset2[name];
    if (value) {
      const newDatas = [...rows];
      newDatas.map((el) => {
        if (el.id == index) {
          el.scope1 = value[0];
          el.scope2 = value[1];
        }
        return el;
      });
      setCalculationData("temp_rows_13_1_1", newDatas);
    }
  };

  useEffect(() => {
    let sum = 0;
    rows.forEach((row) => {
      sum += parseFloat(row.scope1) + parseFloat(row.scope2);
    });
    onChange("result13_1", sum);
  }, [rows]);

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div className="container">
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Franchisee</th>
              <th> Scope 1 emissions (kg CO2e)</th>
              <th>Scope 2 emissions (kg CO2e)</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.Franchises}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.Franchises = e.target.value;
                        }
                        return i;
                      });
                      const newNames = newData.map((row) => row.Franchises);
                      setCompaniesNames(newNames);
                      setCalculationData("temp_rows_13_1_1", newData);
                      handleOnchange(e.target.value, row.id);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.scope1 === 0 ? "NOT FOUND" : row.scope1}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.scope1 = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_13_1_1", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.scope2 === 0 ? "NOT FOUND" : row.scope2}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.scope2 = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_13_1_1", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        Franchises: "",
                        scope1: "",
                        scope2: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_13_1_1", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_13_1_1", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FranchisesSpecificTest;
