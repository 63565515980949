import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import MainPage from "./pages/MainPage";
import SignupPage from "./pages/SignupPage";
import AboutPage from "./pages/AboutPage";
import CalculationPage from "./pages/CalculationPage";
import ContactUsPage from "./pages/ContactUsPage";
import DisplayPage from "./pages/DisplayPage";
import ScopeEmission from "./pages/ScopeEmission";
import VerifyEmail from "./pages/VerifyEmail";
import EmailVerification from "./pages/EmailVerification";
import VideoPage from "./pages/VideoPage";

function Routing() {
  const [sideBarFlag, setSideBarFlag] = useState(false);
  console.log(process.env.REACT_APP_SERVER_URL)

  return (
    <Router basename={process.env.REACT_APP_PUBLIC_URL}>
      <Routes>
        <Route
          exact
          path="/"
          element={
            <MainPage
              sideBarFlag={sideBarFlag}
              setSideBarFlag={setSideBarFlag}
            />
          }
        />
        <Route
          exact
          path="/about"
          element={
            <AboutPage
              sideBarFlag={sideBarFlag}
              setSideBarFlag={setSideBarFlag}
            />
          }
        />
        <Route
          exact
          path="/calculation"
          element={
            <CalculationPage
              sideBarFlag={sideBarFlag}
              setSideBarFlag={setSideBarFlag}
              SERVER_URL={process.env.REACT_APP_SERVER_URL}
            />
          }
        />
        <Route
          exact
          path="/display"
          element={
            <DisplayPage
              sideBarFlag={sideBarFlag}
              setSideBarFlag={setSideBarFlag}
            />
          }
        />
        <Route
          exact
          path="/contact-us"
          element={
            <ContactUsPage
              sideBarFlag={sideBarFlag}
              setSideBarFlag={setSideBarFlag}
            />
          }
        />

        <Route
          exact
          path="/video"
          element={
            <VideoPage
              sideBarFlag={sideBarFlag}
              setSideBarFlag={setSideBarFlag}
            />
          }
        />
        {/* <Route
          exact
          path="/scope-emission"
          element={
            <ScopeEmission
              sideBarFlag={sideBarFlag}
              setSideBarFlag={setSideBarFlag}
            />
          }
        /> */}
        <Route
          path="/verify-email"
          element={
            <VerifyEmail
              sideBarFlag={sideBarFlag}
              setSideBarFlag={setSideBarFlag}
            />
          }
        />
        <Route
          exact
          path="/email-verification"
          element={
            <EmailVerification
              sideBarFlag={sideBarFlag}
              setSideBarFlag={setSideBarFlag}
            />
          }
        />
        <Route
          exact
          path="/login"
          element={<LoginPage SERVER_URL={process.env.REACT_APP_SERVER_URL} />}
        />
        <Route
          exact
          path="/signup"
          element={<SignupPage SERVER_URL={process.env.REACT_APP_SERVER_URL} />}
        />
      </Routes>
    </Router>
  );
}

export default Routing;
