import React, { useEffect, useState } from "react";
import Header from "../components/Header";
// import aboutPageImage from '../assets/images/aboutpage-image.png'
import aboutPageImage from "../assets/images/overview.png";
// import aboutPageImageTwo from '../assets/images/aboutpage-image-2.png'
import aboutPageImageTwo from "../assets/images/plans-and-pricing.png";
import exampleScreenshot from "../assets/images/aboutpage-chart.png";
import Footer from "../components/Footer";
import { ReactComponent as BusinessIcon1 } from "../assets/svg/business-1.svg";
import { ReactComponent as BusinessIcon2 } from "../assets/svg/business-2.svg";
import { ReactComponent as BusinessIcon3 } from "../assets/svg/business-3.svg";
import { useDispatch } from "react-redux";
import { setTab } from "../store/slices/navigation";

function AboutPage({ sideBarFlag, setSideBarFlag }) {
  const [itemNumber, setItemNumber] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTab({ tabId: 1 }));
  });
  return (
    <div className="AboutPage" onClick={() => setSideBarFlag(false)}>
      <Header sideBarFlag={sideBarFlag} setSideBarFlag={setSideBarFlag} />
      {itemNumber === 0 ? (
        <img
          src={aboutPageImage}
          alt="About Page Mage"
          className="about-page-image"
        />
      ) : (
        <img
          src={aboutPageImageTwo}
          alt="About Page Mage"
          className="about-page-image two"
        />
      )}
      <div className="selector">
        <div className="items">
          <div
            className={itemNumber === 0 ? "item selected" : "item"}
            onClick={() => setItemNumber(0)}
          >
            Methodology
          </div>
          <div
            className={itemNumber === 1 ? "item selected" : "item"}
            onClick={() => setItemNumber(1)}
          >
            Subscription Plans
          </div>
        </div>
      </div>
      <div className={itemNumber === 0 ? "description" : "hidden"}>
        <div className="text">
          <div className="row">
            <div className="column">
              <span className="title">Overview</span>
              <span>
                <b>VeriCO₂</b> streamlines the process of calculating your scope
                3 emissions according to the Greenhouse Gas Protocol, providing
                compliance with IFRS S2 Climate Related Disclosure Standards.
              </span>
              <span className="title">How does it work?</span>
              <span>
                <b>VeriCO₂</b> automates the consolidation of scope 1 and 2
                emissions from suppliers and customers and/or the retrieval of
                emission factors to provide the mandatory scope 3 disclosures
                necessary for compliance.
              </span>
            </div>
            <div className="image-card">
              <img
                src={exampleScreenshot}
                alt="Example Screenshot"
                className="example-screenshot"
              />
              {/* <span>Example disclosures required</span> */}
            </div>
          </div>
          {/* <span className='title'>Phase 2</span>
          <span>In its ambitious Phase 2 rollout, <b>VeriCO₂</b> is set to become an essential tool in the fight against greenwashing.
            It will utilise advanced natural language processing algorithms to critically evaluate companies' annual disclosures
            against the stringent requirements of IFRS S2 standards, focused on climate-related risks and opportunities.
            This rigorous analysis will highlight any deficiencies in disclosure, providing clear guidance on what is missing and
            what must be amended to achieve compliance.</span>

          <span>More than just an analytical tool, <b>VeriCO₂</b> aims to encourage corporate responsibility and environmental integrity.
            By identifying companies with robust carbon disclosure alignment and commendable emissions performance, <b>VeriCO₂</b> sets the
            stage for the fair distribution of environmental credits to those buyers that demonstrate a genuine commitment to sustainability.
            With <b>VeriCO₂</b>, stakeholders can make more informed decisions, fostering a more transparent and accountable business environment.
          </span> */}
        </div>
        <span className="footer-text">
          Call customer support to talk about your individual plan, customer
          service email: <b>admin@VeriCO2.com</b>
        </span>
      </div>
      <div className={itemNumber === 1 ? "description" : "hidden"}>
        <div className="pricings">
          <div className="price-box">
            <div className="top">
              <BusinessIcon1 />
              <span className="title">Single Use</span>
              <span className="price">$ 3,889</span>
            </div>
            <div className="bottom">
              <span className="title">This plan includes:</span>
              <span>- Unlimited Platform Access 1Yr, 1 User</span>
              <span>
                - Automated scope 3 emissions quantification for mandatory
                reporting
              </span>
              <span>- Exportable Reports</span>
              <span>- Facilitated Onboarding</span>
              <span>- Email Support</span>
            </div>
          </div>
          <div className="price-box">
            <div className="top">
              <BusinessIcon2 />
              <span className="title">Maximum Three Users</span>
              <span className="price">$ 8,000</span>
            </div>
            <div className="bottom">
              <span className="title">This plan includes:</span>
              <span>
                - Unlimited Platform Access 1Yr, 1 User, Up to Three Searches
              </span>
              <span>
                - Automated scope 3 emissions quantification for mandatory
                reporting
              </span>
              <span>- Exportable Reports</span>
              <span>- Facilitated Onboarding</span>
              <span>- Email Support</span>
            </div>
          </div>
          <div className="price-box">
            <div className="top">
              <BusinessIcon3 />
              <span className="title">Multiple users</span>
              <span className="price">$ 30,000</span>
            </div>
            <div className="bottom">
              <span className="title">This plan includes:</span>
              <span>
                - Unlimited Platform Access 1Yr, 1 User, Unlimited Platform
                Searches
              </span>
              <span>
                - Automated scope 3 emissions quantification for mandatory
                reporting
              </span>
              <span>- Exportable Reports</span>
              <span>- Facilitated Onboarding</span>
              <span>- Email Support</span>
            </div>
          </div>
        </div>
        <span className="footer-text">
          Call Customer Support to talk about your Individual Plan, Customer
          Service Email: <b>admin@VeriCO2.com</b>
        </span>
      </div>

      <Footer />
    </div>
  );
}

export default AboutPage;
