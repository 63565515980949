import React, { useState, useEffect, useRef } from "react";
import "../../App.scss";
import Add from "../../assets/images/Add.png";
import Delete from "../../assets/images/Delete.png";
import { useDispatch } from "react-redux";
import useRTKStore from "../../store/useRTKStore";
import { setCalculation } from "../../store/slices/calculation";
function UseDirect({ onChange, dataset1 }) {
  const currentRef = useRef();
  const dispatch = useDispatch();
  const { temp_rows_10_1 } = useRTKStore("calculation", ["temp_rows_10_1"]);
  const rows = JSON.parse(JSON.stringify(temp_rows_10_1));

  currentRef.current = false;

  useEffect(() => {
    if (!dataset1 || Object.keys(dataset1).length == 0) return;

    const k = [].concat([], rows);
    rows.forEach((d, idx) => {
      if (dataset1.hasOwnProperty(k[idx].Product)) {
        k[idx].factor = dataset1[k[idx].Product];
      } else {
        k[idx].factor = k[idx].factor;
      }
    });
    setCalculationData("temp_rows_10_1", k);
  }, [dataset1]);

  const setCalculationData = (key, value) => {
    dispatch(
      setCalculation({
        key,
        value,
      }),
    );
  };

  useEffect(() => {
    let sum = 0;
    rows.forEach((row) => {
      sum +=
        parseFloat(row.tul) *
        parseFloat(row.ns) *
        parseFloat(row.ecu) *
        parseFloat(row.factor);
    });
    onChange("result10_1", sum);
  }, [rows]);

  return (
    // <div className='SignupPage' onClick={() => setfake1(true)}>
    <div className="container">
      <span className="title">Input Data</span>
      <div className="table-container">
        <table>
          <tbody>
            <tr>
              <th>Product</th>
              <th>Total uses over lifetime</th>
              <th>Number sold</th>
              <th>Electricity consumed per use(kWh)</th>
              <th>Electricity emission factor(KgCO2e/kWh)</th>
            </tr>
            {rows.map((row) => (
              <tr>
                <td>
                  <input
                    type="text"
                    value={row.Product}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.Product = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.tul}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.tul = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.ns}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.ns = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_1", newData);
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.ecu}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.ecu = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_1", newData);
                    }}
                  />
                </td>
                <td className="AddStyle">
                  <input
                    type="text"
                    value={row.factor === 0 ? "NOT FOUND" : row.factor}
                    className="Input_form"
                    onChange={(e) => {
                      const newData = rows.map((i) => {
                        if (i.id === row.id) {
                          i.factor = e.target.value;
                        }
                        return i;
                      });
                      setCalculationData("temp_rows_10_1", newData);
                    }}
                  />
                  <img
                    src={Add}
                    alt="Add"
                    className="AddButton"
                    onClick={() => {
                      const newRow = {
                        id: Date.now(),
                        Product: "",
                        tul: "",
                        ns: "",
                        ecu: "",
                        factor: "",
                      };
                      let pos =
                        rows.indexOf(rows.find((item) => item.id === row.id)) +
                        1;

                      const newData = [].concat(
                        rows.slice(0, pos),
                        newRow,
                        rows.slice(pos),
                      );
                      setCalculationData("temp_rows_10_1", newData);
                    }}
                  />
                  <img
                    src={Delete}
                    alt="Delete"
                    className="AddButton"
                    onClick={() => {
                      let pos = rows.indexOf(
                        rows.find((item) => item.id == row.id),
                      );
                      const newData = [].concat(
                        rows.slice(0, pos),
                        rows.slice(pos + 1),
                      );
                      setCalculationData("temp_rows_10_1", newData);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UseDirect;
