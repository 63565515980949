import { useSelector } from "./store";

/*
  Following hook accepts two arguments: entity and attributes
    - entity name must be of type string
    - attributes must be of type Array of string. Default value of attributes is ['all']
    - default value returns all the data for the specified entity
*/

const useRTKStore = (entity, attributes) => {
  const data = useSelector((state) => state[entity]);

  const result = {};
  if (!Array.isArray(attributes)) return;

  attributes.forEach((attribute) => {
    result[attribute] = data[attribute];
  });
  return result;
};

export default useRTKStore;
